



























import { Vue, Component } from 'vue-property-decorator';
import { Action } from 'vuex-class';

@Component
export default class AdvancedSearchTypeSwitcher extends Vue {
    @Action navigateToOtherSectionInAdvancedSearch;
}
