import { render, staticRenderFns } from "./Loading.vue?vue&type=template&id=d2b2c750&"
import script from "./Loading.vue?vue&type=script&lang=js&"
export * from "./Loading.vue?vue&type=script&lang=js&"
import style0 from "./Loading.vue?vue&type=style&index=0&module=true&lang=scss&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports