<template>
    <div id="register-app">
        <router-view />
    </div>
</template>

<script>
    import debounce from './helpers/debounce';

    export default {
        data() {
            return {
                width: 0,
            };
        },

        watch: {
            width(value) {
                if (value > 740) {
                    this.$store.commit('setAppSize', 'app-size-large');
                } else if (value > 460) {
                    this.$store.commit('setAppSize', 'app-size-medium');
                } else {
                    this.$store.commit('setAppSize', null);
                }
            },
        },

        mounted() {
            this.width = this.$el.offsetWidth;

            window.addEventListener('resize', debounce(() => {
                this.width = this.$el.offsetWidth;
            }));
        },
    };
</script>
