































import { VPopover } from 'v-tooltip';
import Images from '../mixins/images';
import Routes from '../mixins/routes';
import { Component, Prop } from 'vue-property-decorator';
import Trademark from '@/models/Trademark';
import { mixins } from 'vue-class-component';
// @ts-ignore
import VClamp from 'vue-clamp';
import MutationHeader from '@/components/Mutations/MutationHeader.vue';
import MutationTable from '@/components/Mutations/MutationTable.vue';
import TrademarkTable from '@/components/Trademarks/TrademarkTable.vue';
import TrademarkHeader from '@/components/Trademarks/TrademarkHeader.vue';

@Component({
    components: {
        MutationHeader,
        VPopover,
        VClamp,
    },
})
export default class GridListItem extends mixins(Images, Routes) {
    @Prop() trademark!: Trademark;

    get tableComponent() {
        if (this.isMutationRoute) {
            return MutationTable;
        }

        return TrademarkTable;
    }

    get tableHeaderComponent() {
        if (this.isMutationRoute) {
            return MutationHeader;
        }

        return TrademarkHeader;
    }

    get trademarkName() {
        return this.trademark.name;
    }
}
