















































































































































































import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import Trademark from '@/models/Trademark';

@Component
export default class OrderCopy extends Vue {
    applicationNumber: string = '';
    registrationNumber: string = '';
    trademarkName: string = '';
    numberOfStatements: string = '';
    company: string = '';
    name: string = '';
    street: string = '';
    zipCode: string = '';
    city: string = '';
    country: string = '';
    phone: string = '';
    email: string = '';
    currentAccount: string = '';
    reference: string = '';

    loading: boolean = false;
    error: boolean = false;

    @Getter trademarkById: any;
    @Action fetchItem: any;
    @Action orderCopy: any;

    mounted() {
        const trademarkId = this.$attrs.id;

        this.fetchItem(trademarkId).then(() => {
            const trademark = this.trademarkById(trademarkId) as Trademark;

            this.applicationNumber = trademark.applicationNumber;
            this.registrationNumber = trademark.registrationNumber;
            this.trademarkName = trademark.name;
        });
    }

    submit() {
        this.loading = true;
        this.error = false;

        const data = {
            language: document.documentElement.lang.toUpperCase(),
            applicationNumber: this.applicationNumber,
            registrationNumber: this.registrationNumber,
            markVerbalElementText: this.trademarkName,
            quantity: this.numberOfStatements,
            company: this.company,
            name: this.name,
            street: this.street,
            zipCode: this.zipCode,
            city: this.city,
            country: this.country,
            telephoneNumber: this.phone,
            sender: this.email,
            currentAccount: this.currentAccount,
            reference: this.reference,
        };

        this.orderCopy(data).then(() => {
            this.$router.push({ name: 'item-order-success' });
        }).catch(error => {
            this.loading = false;
            this.error = true;

            // eslint-disable-next-line no-console
            console.error(error);
        });
    }
}
