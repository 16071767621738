<template>
    <div v-if="searchingError" :class="$style.error">
        {{ searchingError }}
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        name: 'SearchErrors',

        computed: {
            ...mapState(['searchingError']),
        },
    };
</script>

<style module lang="scss">
    .error {
        color: red;
        margin-top: 2rem;
    }
</style>
