


































import { Component, Prop } from 'vue-property-decorator';
import Trademark from '@/models/Trademark';
import { mixins } from 'vue-class-component';
import MagnifierIcon from '@/assets/icons/magnifier.svg';
import Images from '../mixins/images';
import Routes from '../mixins/routes';
// @ts-ignore
import VClamp from 'vue-clamp';
import MutationHeader from '@/components/Mutations/MutationHeader.vue';
import TrademarkHeader from '@/components/Trademarks/TrademarkHeader.vue';
import MutationTable from '@/components/Mutations/MutationTable.vue';
import TrademarkTable from '@/components/Trademarks/TrademarkTable.vue';

@Component({
    components: {
        MutationHeader,
        MagnifierIcon,
        VClamp,
    },
})
export default class RowListItem extends mixins(Images, Routes) {
    @Prop() trademark!: Trademark;

    get headerComponent() {
        if (this.isMutationRoute) {
            return MutationHeader;
        }

        return TrademarkHeader;
    }

    get tableComponent() {
        if (this.isMutationRoute) {
            return MutationTable;
        }

        return TrademarkTable;
    }

    get modalName() {
        // TODO: Refactor this to be not _uid (is private, can change at any time)...
        // @ts-ignore
        return `imageFull-${this._uid}`;
    }

    get hasMultipleTrademarks() {
        return this.trademark.trademarkList && this.trademark.trademarkList.length > 1;
    }

    get modalHeader(): string {
        if (!this.name) {
            return '';
        }

        if (this.isMutationRoute) {
            return this.name;
        }

        let trademarkKind = this.trademark.kind;

        return `${this.name}`;
    }

    get name(): string {
        if (this.trademark.trademarkList && this.trademark.trademarkList.length > 0) {
            return this.trademark.trademarkList[0].name;
        }

        return this.trademark.name;
    }

    openImageModal() {
        if (!this.imageThumbnail) {
            return;
        }

        this.$modal.show(this.modalName);
    }
}
