





















import Paginate from 'vuejs-paginate';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    components: {
        Paginate,
    },
    model: {
        prop: 'page',
        event: 'change',
    },
})
export default class PaginationLinks extends Vue {
    @Prop({ required: true }) page!: number;
    @Prop({ required: true }) pageCount!: number;

    setPage(page) {
        this.$emit('change', page);
    }

    get maxPageCount() {
        const maxPageCount = 833;

        if (this.pageCount > maxPageCount) {
            return maxPageCount;
        }

        return this.pageCount;
    }
}
