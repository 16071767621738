




































import BaseFilter from './forms/Base.vue';
import { Vue, Component } from 'vue-property-decorator';
import { Action, Mutation, State } from 'vuex-class';
import OfficeDecisionsSearch from '@/views/OfficeDecisionsSearch.vue';
import OfficeDecisionsSearchPopup from '@/components/OfficeDecisionsSearchPopup.vue';

@Component({
    components: {
        OfficeDecisionsSearch,
        BaseFilter,
        OfficeDecisionsSearchPopup,
    },
})
export default class OfficeDecisionsSearchForm extends Vue {
    @State officeDecisionsFields;
    @State officeDecisions;
    @Action removeOfficeDecisionsField;
    @Action setOfficeDecisionsField;
    @Action advancedSearch;
    @Action resetOffice;
    @Mutation setPage;

    popupVisible: boolean = false;

    updateState(field, value) {
        this.setOfficeDecisionsField({ field, value });
    }

    isComponentNonRemovable(component: string) {
        return [
            'refusalReason',
            'refusals',
            'definitiveRefusalDate',
        ].includes(component);
    }

    search() {
        this.resetOffice();
        this.setPage(1);
        this.advancedSearch({
            ...this.officeDecisions,
            exact: this.$store.state.advancedSearch.exact,
        });
    }
}
