
























































import { Component, Prop, Vue } from 'vue-property-decorator';
import ImageInput from '@/components/forms/ImageInput.vue';
import Query from '@/models/Query';
import { QueryType } from '@/enums/QueryType';
import { ImageSearchType } from '@/enums/ImageSearchType';
import CameraIcon from '@/assets/icons/camera.svg';
import HelpText from '@/components/HelpText.vue';
import EnableImageModeModal from '@/components/EnableImageModeModal.vue';
import { Action } from 'vuex-class';

@Component({
    components: { EnableImageModeModal, ImageInput, CameraIcon, HelpText },
})
export default class WordOrImage extends Vue {
    @Prop({ required: true })
    value!: Query|null;

    @Prop({ default: false })
    simple!: boolean;

    @Action('setAdvancedSearchFields')
    setAdvancedSearchFields!: (filters: Array<string>) => void;

    queryType = QueryType;
    imageSearchType = ImageSearchType;
    modalName: string = 'wordOrImageModal';

    imageSearch: boolean = false;

    displayModal() {
        if (this.simple) {
            this.enableImageSearch();
        } else {
            this.$modal.show(this.modalName);
        }
    }

    enableImageSearch() {
        this.$store.commit('setImageMode', true);
        this.imageSearch = true;
    }

    disableImageSearch() {
        this.$store.commit('setImageMode', false);
        this.imageSearch = false;
    }

    deleteImage() {
        this.query = null;
    }

    displayError(error) {
        this.$store.commit('setSearchingError', error);
    }

    get required(): boolean {
        return this.$route.name !== 'advanced-search';
    }

    get term(): string {
        return this.query ? this.query.value : '';
    }

    set term(value: string) {
        this.query = { value: value, type: QueryType.TEXT };
    }

    get query(): Query {
        return this.value;
    }

    set query(value: Query) {
        this.$emit('input', value);
    }

    get exact(): boolean {
        return this.$store.state.advancedSearch.exact;
    }

    set exact(value: boolean) {
        this.$store.commit('setAdvancedSearchFieldValue', {
            value: value,
            field: 'exact',
        });
    }

    get searchType(): ImageSearchType {
        return this.$store.state.advancedSearch.imageSearchType;
    }

    set searchType(value: ImageSearchType) {
        this.$store.commit('setAdvancedSearchFieldValue', {
            value: value,
            field: 'imageSearchType',
        });
    }
}
