import Vue from 'vue';
import Vuex from 'vuex';
import state from './state';
import * as actions from './actions';
import * as getters from './getters';
import * as mutations from './mutations';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

const store = new Vuex.Store({
    strict: process.env.NODE_ENV !== 'production',
    state,
    getters,
    mutations,
    actions,
    plugins: [
        createPersistedState({
            key: 'boip-registers-state',
            paths: [
                'advancedSearch',
                'expires',
                'mutationSearch',
                'term',
                'fields',
                'count',
                'searched',
                'searchedWithImage',
                'trademarks',
                'trademarkList',
                'query',
                'office',
            ],
            getState: (key, storage, value) => {
                try {
                    let storageItems = (value = storage.getItem(key)) && typeof value !== 'undefined'
                        ? JSON.parse(value)
                        : undefined;

                    // Check if storage has expired...
                    if (storageItems.expires <= new Date()) {
                        localStorage.removeItem(key);

                        return undefined;
                    }

                    return storageItems;
                } catch (err) {}

                return undefined;
            },
        }),
    ],
});

if (module.hot) {
    module.hot.accept([
        './actions',
        './getters',
        './mutations',
    ], () => {
        store.hotUpdate({
            actions: require('./actions').default,
            getters: require('./getters').default,
            mutations: require('./mutations').default,
        });
    });
}

export default store;
