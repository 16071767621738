

















































import Forms from '@/mixins/forms';
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import Multiselect from 'vue-multiselect';
import { Action, State } from 'vuex-class';

@Component({ components: { Multiselect } })
export default class AbsoluteGrounds extends mixins(Forms) {
    @Prop({ default: '' }) value: string;
    @State officeDecisions;
    @Action setOfficeDecisionsField;

    selectValue: Array<string> = [];

    get andOr() {
        return this.officeDecisions.refusalReasonOperator;
    }

    set andOr(value) {
        this.setOfficeDecisionsField({
            value: value,
            field: 'refusalReasonOperator',
        });
    }

    get selectOptions() {
        return [
            { value: 'A', label: this.$t('officeDecisions.absoluteGrounds.a') },
            { value: 'B', label: this.$t('officeDecisions.absoluteGrounds.b') },
            { value: 'C', label: this.$t('officeDecisions.absoluteGrounds.c') },
            { value: 'D', label: this.$t('officeDecisions.absoluteGrounds.d') },
            { value: 'E', label: this.$t('officeDecisions.absoluteGrounds.e') },
            { value: 'F', label: this.$t('officeDecisions.absoluteGrounds.f') },
            { value: 'G', label: this.$t('officeDecisions.absoluteGrounds.g') },
            { value: 'H', label: this.$t('officeDecisions.absoluteGrounds.h') },
            { value: 'I', label: this.$t('officeDecisions.absoluteGrounds.i') },
            { value: 'J', label: this.$t('officeDecisions.absoluteGrounds.j') },
            { value: 'K', label: this.$t('officeDecisions.absoluteGrounds.k') },
            { value: 'L', label: this.$t('officeDecisions.absoluteGrounds.l') },
        ];
    }

    remove(option) {
        (this.$refs as any).multiselect.removeElement(option);

        this.update(this.selectValue);
    }

    update(options) {
        this.$emit('input', options);
    }
}
