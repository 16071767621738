










import RowListItem from '@/components/RowListItem.vue';
import { Component, Vue, Prop } from 'vue-property-decorator';
import TrademarkParser from '@/helpers/TrademarkParser';

@Component({
    components: {
        RowListItem,
    },
})
export default class RowList extends Vue {
    @Prop({ required: true })
    trademarks!: [];

    convertToTrademarkClass(trademark: any) {
        return TrademarkParser.parse(trademark);
    }
}
