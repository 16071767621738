






















import HelpText from '@/components/HelpText.vue';
import { Component, Vue } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';

@Component({
    components: {
        HelpText,
    },
})
export default class ResultFilter extends Vue {
    @Action setOffice;
    @Action setAdvancedOffice;
    @Action setOfficeDecisionsOffice;

    @State office!: string;

    get filter() {
        return this.office;
    }

    set filter(office) {
        switch (this.$route.name) {
            case 'advanced-search':
                this.setAdvancedOffice(office);
                return;
            case 'office-decisions-search':
                this.setOfficeDecisionsOffice(office);
                return;
            default:
                this.setOffice(office);
        }
    }
}
