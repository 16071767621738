<template>
    <div :class="$style['text-align-center']">
        <LoadingIcon v-if="searching || isLoading" />
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import LoadingIcon from '@/components/loading/LoadingIcon';

    export default {
        components: {
            LoadingIcon,
        },

        props: {
            isLoading: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            ...mapState(['searching']),
        },
    };
</script>

<style module lang="scss">
    .text-align-center {
        text-align: center;
    }
</style>
