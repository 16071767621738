import { QueryType } from '@/enums/QueryType';

export const trademarkSet = state => {
    let trademarks = state.trademarkList.map(registrationNumber => state.trademarks[registrationNumber]);

    return trademarks.map(trademark => {
        if (!trademark.trademarkList) {
            return trademark;
        }

        const firstTrademark = trademark.trademarkList[0];

        if (!firstTrademark) {
            return {
                ...trademark,
                name: trademark.id,
                images: null,
            };
        }

        return {
            ...trademark,
            registrationNumber: firstTrademark.id,
            name: trademark.id,
            office: firstTrademark.office,
            images: {
                thumbnail: firstTrademark.imageUrl,
            },
        };
    });
};

export const trademarkById = state => id => {
    return state.trademarks[id];
};

export const queryContainsImage = (state) => {
    return state.advancedSearch.wordOrImage && state.advancedSearch.wordOrImage.type === QueryType.IMAGE;
};
