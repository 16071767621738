

































import { Component, Vue } from 'vue-property-decorator';
import { Getter, State } from 'vuex-class';
import RowList from '@/components/RowList.vue';
import GridList from '@/components/GridList.vue';
import HelpText from '@/components/HelpText.vue';
import ResultHeader from '@/components/ResultHeader.vue';

@Component({
    components: {
        HelpText,
        ResultHeader,
    },
})
export default class SearchResults extends Vue {
    @State count;
    @State view;
    @State searched;
    @State query;
    @State advancedSearch;
    @State searchedWithImage;

    @Getter('trademarkSet')
    trademarks;

    get listComponent() {
        if (this.view === 'grid') {
            return GridList;
        }

        return RowList;
    }
}
