export default {
    forms: {
        date: {
            from: 'from',
            to: 'to',
        },
    },

    print: {
        header: 'Trademarks Register Sint Maarten',
    },

    search: {
        advancedSearch: 'Advanced search',
        exact: 'Exact search term',
        filter: 'Filter search by',
        filter_add: 'Add search filter',
        filter_help: 'With both checkboxes selected, the search result shows SXM trademarks as well as international registrations designating Sint Maarten (SX).\nYou only want to see SXM trademarks? Please deselect INT.\nYou only want to see international registrations designating SX? Please deselect SXM.',
        no_results: 'No results found. Please try again',
        search_results: 'Search results',
        searchingError: 'An error occurred while searching, please try again later.',
        searchingFor: 'Search for',
        searchingForBrand: 'Trademarks',
        searchingForMutation: 'Recordals',
        searchingErrorAtLeastOneFilter: 'At least one filter is required.',
        simpleSearch: 'Simple search',
        submit: 'Search',
        submit_help: 'Search by registration number or by a combination of letters and numbers contained in a trademark.The search result only shows published trademarks containing the exact search term, regardless of its position in the trademark. \nIf you want to search for similar trademarks, please use the \'Advanced search\' option and deselect the checkbox \'Exact search term\'.',
        term: 'Search terms',
        total: 'Total',
        itemNotFound: 'Your search cannot be processed at the moment.',
        itemNotFoundUrl: 'Please try again here.',

        fields: {
            depotDate: 'Filing date',
            goods: 'Goods and services',
            holder: 'Holder',
            imageMark: 'Device mark classification',
            mutationNumber: 'Recordal number',
            mutationType: 'Recordal type',
            nice: 'Nice classification',
            publicationDateMutation: 'Publication date recordal',
            publicationDate: 'Publication date registration',
            renewalDate: 'Publication date renewal',
            registration: 'Registration number',
            word: 'Verbal element',

            help: {
                depotDate: 'Enter a start and end date to search within a specific period.',
                goods: 'Search for trademarks which have designated the selected goods and/or services. You search for the terms in the language you use.',
                holder: 'Make a selection from our customer base to search for a trademark of a specific applicant (trademark holder).',
                imageMark: 'You can search for a logo (device mark) using an assigned code. All logos are classified according to an international standard, the Vienna Classification.',
                mutationNumber: 'Fill in the number of the recordal you are looking for.',
                mutationType: 'Search for recordals of a certain type.\nYou are obliged to choose a recordal type.',
                nice: 'You can search for a trademark in a specific goods or services category by making a selection from the Nice Classification. A trademark registration is always for specific goods and/or services. These goods and services are ranked in classes in accordance with an international standard, the Nice Classification. Search here by class number.',
                publicationDateMutation: 'Search for recordals of a certain type that were published during the selected date range.\nYou are obliged to specify a date range of publication.',
                publicationDate: 'Enter a start and end date to search within a specific period.',
                renewalDate: 'Search for SXM trademarks that have been renewed during a certain period.',
                registration: 'Fill in the registration number of the trademark you are looking for.',
                word: 'Search on alphanumeric elements of a trademark.\nWhen the checkbox \'Exact search term\' is selected, the search result will only show published trademarks containing the exact search term, regardless of its position in the trademark.\nIf you want to search for similar trademarks (in which some characters are different), deselect the checkbox.',
            },
        },

        office: {
            sxm: 'SXM',
            int: 'INT',
        },
    },

    advanced_search: {
        add: 'Add',
        brand_information: 'Trademark information',
        data: 'Dates',
        header: 'Add here 1 or more search terms',
        holder: 'Holder',
        mutations: 'Recordals',

        help: {
            brand_information: 'Search for trademarks based on word elements, goods and services, image elements, registration number or a combination thereof.\nYou search both within the Sint Maarten trademarks and the international trademarks with validity in Sint Maarten.',
            data: 'Use these search fields to search for trademarks that fall within this date range. \nYou search both within the Sint Maarten trademarks and the international trademarks with validity in Sint Maarten.',
            holder: 'Use the search term "holder" to find all the trademarks of a particular trademark holder.',
        },
    },

    item: {
        back: 'Back to search results',
        orderCopy: 'Order copy',
        print: 'Print',
        savePdf: 'Save as PDF',
    },

    mutation: {
        depotNumbers: 'Registration number(s)',
        description: 'Recordal description',
        information: 'Recordal information',
        number: 'Recordal number',
        publicationDate: 'Publication date',
        signedDate: 'Registration date',
        status: 'Status',
        type: 'Recordal type',
        searchingError: 'An error has occurred. You can only search by recordal number or by the combination recordal type and publication date recordal.',
    },

    trademark: {
        acquiredRights: 'Acquired rights in the Netherlands Antilles',
        applicant: 'Holder',
        applicantDescription: 'Name and address of the holder',
        applicationDate: 'Date of renewal request',
        correspondenceAddress: 'Correspondence address',
        correspondenceAddressAndName: 'Name and address of the authorized representative or the correspondence address of the holder',
        depotDate: 'Date and time of filing',
        expirationDate: 'Expiration date',
        goodsAndServices: 'Goods and services',
        goodsAndServicesDescription: 'List of goods and services',
        goodsAndServicesNumber: 'Class numbers',
        goodsAndServicesPrefix: 'Cl',
        image: 'Representation of the mark',
        kind: 'Kind',
        feature: 'Type',
        mark: 'Trademark',
        markInformation: 'Trademark information',
        moreinfo: 'More details',
        mutation: 'Recordal number',
        mutationDetails: 'Recordal information',
        mutationRegistered: 'Registered',
        mutations: 'Recordals',
        mutationStatus: 'Status',
        mutationType: 'Recordal type',
        publicationDateRegistration: 'Publication date of the registration',
        publicationDateRenewal: 'Publication date of the renewal',
        registrationDate: 'Registration date',
        registrationNumber: 'Registration number',
        representative: 'Representative',
        representativeDescription: 'Name and address of the representative',
        rightOfPriority: 'Right of priority',
        status: 'Status',
        viennaCodes: 'Classification of figurative elements, colour(s) and/or distinctive elements',
    },
};
