



























import Dates from '../../mixins/dates';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import Trademark from '@/models/Trademark';

@Component
export default class MutationTable extends Mixins(Dates) {
    @Prop({ required: true })
    trademark!: Trademark;

    get depotNumbers(): string {
        if (!this.trademark.trademarkList) {
            return '';
        }

        return this.trademark.trademarkList.reduce((acc, trademark) => {
            acc.push(trademark.registrationNumber);

            return acc;
        }, []).join(', ');
    }
}
