<template>
    <div>
        <div :class="$style.itemHeader">
            <hr v-if="!error" :class="$style.separator">

            <div :class="$style.flex">
                <div :class="$style.alignItemsCenter">
                    <router-link v-if="previousRoute" :class="$style.buttonLink" tag="button" :to="previousRoute">
                        {{ $t('item.back') }}
                    </router-link>
                </div>

                <div v-if="item" :class="[$style.flexGrow, $style.textAlignRight]">
                    <button v-if="!isAndroid()" type="button" :class="$style.button" @click="print()">
                        {{ $t('item.print') }}
                    </button>
                </div>
            </div>

            <hr :class="$style.separator">
        </div>

        <Loading :is-loading="!item && !error" />
        <p v-if="!item && error && query === '' && advancedSearch.goods === '' && advancedSearch.word === ''" :class="$style.error">
            {{ $t('search.itemNotFound') }} <router-link :to="{ name: 'mutation-search' }" :class="[$style.inlineButtonLink, $style.buttonLink]">
                {{ $t('search.itemNotFoundUrl') }}
            </router-link>
        </p>

        <p v-else-if="!item && error" :class="$style.error">
            {{ $t('search.itemNotFound') }} <router-link :to="{ name: 'simple-search' }" :class="[$style.inlineButtonLink, $style.buttonLink]">
                {{ $t('search.itemNotFoundUrl') }}
            </router-link>
        </p>

        <Portal target-el="#register-app-print">
            <table>
                <thead>
                    <tr>
                        <th>
                            <img alt="BOIP logo" src="@/assets/images/logo.png" :class="$style.beneluxLogo" align="left">
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td :class="$style.tableHeader">
                            <h1>{{ $t('print.header') }}</h1>
                        </td>
                    </tr>
                </tbody>
            </table>

            <component :is="detailComponent" v-if="item" :trademark="item" />
        </Portal>

        <component :is="detailComponent" v-if="item" :trademark="item" />

        <hr :class="$style.separator">
        <router-link v-if="previousRoute" :class="$style.buttonLink" tag="button" :to="previousRoute">
            {{ $t('item.back') }}
        </router-link>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex';
    import { Portal } from 'portal-vue';
    import Loading from '@/components/loading/Loading.vue';
    import Routes from '@/mixins/routes';
    import TrademarkParser from '../helpers/TrademarkParser';
    import MutationDetail from '@/components/Mutations/MutationDetail';
    import TrademarkDetail from '@/components/Trademarks/TrademarkDetail';

    export default {
        name: 'Item',

        components: {
            TrademarkDetail,
            MutationDetail,
            Portal,
            Loading,
        },

        mixins: [Routes],

        data() {
            return {
                previousRoute: null,
                error: null,
            };
        },

        beforeRouteEnter(to, from, next) {
            next(vm => {
                if (window.history.length <= 2) {
                    return;
                }

                if (from.name === 'item-order') {
                    vm.previousRoute = { name: 'simple-search' };

                    return;
                }

                vm.previousRoute = from;
            });
        },

        computed: {
            item() {
                let trademark = TrademarkParser.parse(this.$store.state.trademarks[this.$route.params.id]);

                if (!trademark) {
                    // eslint-disable-next-line
                    this.error = true;

                    return;
                }

                return trademark;
            },

            detailComponent() {
                if (this.isMutationRoute) {
                    return MutationDetail;
                }

                return TrademarkDetail;
            },

            ...mapState([
                'query',
                'advancedSearch',
            ]),
        },

        watch: {
            $route() {
                this.getItem(this.$route.params.id);
            },
        },

        created() {
            this.getItem(this.$route.params.id);

            window.scrollTo(0, 0);
        },

        methods: {
            ...mapActions(['fetchItem', 'fetchMutationItem']),

            getItem(id) {
                if (this.isMutationRoute) {
                    this.fetchMutationItem(this.$route.params.id);

                    return;
                }

                this.fetchItem(this.$route.params.id);
            },

            print() {
                window.print();
            },

            isAndroid() {
                return navigator.userAgent.toLowerCase().indexOf('android') > -1;
            },

            order() {
                this.$router.push({ name: 'item-order', params: { id: this.item.id } });
            },

            back() {
                window.history.go(-1);
            },
        },
    };
</script>

<style module lang="scss">
    @media print {
        .boipLogo {
            width: 20rem !important;
        }

        .beneluxLogo {
            width: 8rem !important;
            margin-bottom: 2rem;
        }

        .tableHeader {
            padding-bottom: 8rem;
        }

        .tableFooter {
            color: black;
            font-weight: normal;
        }
    }

    .button {
        @include button-primary();

        margin: 0 0 0 1.6rem;
    }

    .inlineButtonLink {
        vertical-align: baseline!important;
    }

    .buttonSecondary {
        @include button-secondary();

        margin: 0 0 0 1.6rem;
    }

    .buttonLink {
        @include button-link();
    }

    .flex {
        display: flex;
        flex-flow: wrap;

        .flexGrow {
            flex: 1;
        }
    }

    .textAlignRight {
        text-align: right;
    }

    .backToSearchResultsBottom {
        margin-top: 3.7rem;
        margin-bottom: 9.4rem;
    }

    .textAlignCenter {
        text-align: center;
    }

    .alignItemsCenter {
        display: flex;
        align-items: center;
    }

    .separator {
        border: none;
        display: inline-block; // Prevent margins collapsing
        height: 1rem;
        margin: 0;
        position: relative;
        width: 100%;

        &:before {
            border-top: 1px solid $color-border;
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            width: 100%;
        }
    }

    .itemHeader {
        margin-bottom: 3.9rem;
    }

    .error {
        margin-left: 16px;
        margin-bottom: 3.2rem;
    }
</style>

<style lang="scss">
    @media print {
        body > * {
            display: none !important;
        }

        #register-app-print {
            display: block !important;
        }

        #register-app-print img {
            width: 12rem !important;
            height: auto;
        }

        @page {
            size: A4;
            margin: 11mm 17mm 17mm 17mm;
            padding: 200mm;
        }

        h1 {
            padding: 0 !important;
            max-width: 60%;
            font-size: 2.4rem !important;
            line-height: 3.2rem !important;
        }

        h2 {
            margin-top: 2.6rem !important;
            font-weight: bold !important;
            font-size: 2rem !important;
            padding: 0 !important;
            background-color: transparent !important;
            border-bottom: 2px solid $color-border;
            border-radius: 0 !important;
        }

        table {
            background-color: white !important;
            border: 0 !important;
            font-size: 1.4rem;
        }

        tr {
            background-color: white !important;
            border: 0 !important;
        }

        th {
            background-color: white !important;
            border: 0 !important;
            padding: 0 !important;
            clear: left !important;
            color: $color-primary !important;
            font-weight: bold !important;
        }

        td {
            background-color: white !important;
            border: 0 !important;
            padding: 0 !important;
            padding-left: 15px !important;

            &:first-child {
                padding: 0 !important;
                clear: left !important;
            }
        }
    }

    #register-app-print {
        display: none;
    }
</style>
