<template>
    <div :class="$style[$store.state.appSize]">
        <form accept-charset="UTF-8" @submit.prevent="search">
            <div v-for="component in mutationFields" :key="component._uid">
                <BaseFilter :value="formData[component]" :component="component" :name="component" @input="updateState(component, $event)" />
            </div>

            <div :class="$style.buttonWrapper">
                <button type="submit" :class="$style.buttonCTA" data-testid="mutation-submit-btn">
                    {{ $t('search.submit') }}
                </button>
            </div>
        </form>
    </div>
</template>

<script>
    import BaseFilter from '../forms/Base.vue';

    export default {
        name: 'MutationSearchForm',

        components: {
            BaseFilter,
        },

        data() {
            return {
                mutationFields: [
                    'mutationNumber',
                    'publicationDateMutation',
                    'mutationType',
                ],
            };
        },

        computed: {
            formData() {
                return this.$store.state.mutationSearch;
            },
        },

        mounted() {
            this.setInitialFields();
        },

        methods: {
            updateState(field, value) {
                this.$store.commit('setMutationSearchFieldValue', { field, value });
            },

            search() {
                this.$store.commit('setPage', 1);
                this.$store.dispatch('mutationSearch');
            },

            setInitialFields() {
                if (this.formData.mutationType === undefined) {
                    this.$store.commit('setMutationSearchFieldValue', {
                        field: 'mutationType',
                        value: '',
                    });
                }

                if (this.formData.publicationDateMutation === undefined) {
                    this.$store.commit('setMutationSearchFieldValue', {
                        field: 'publicationDateMutation',
                        value: {
                            from: '',
                            to: '',
                        },
                    });
                }
            },
        },
    };
</script>

<style module lang="scss">
    @include input;

    .buttonWrapper {
        display: flex;
        flex-direction: column;

        .app-size-medium &, .app-size-large & {
            flex-direction: row;
            justify-content: flex-end;
            align-items: flex-end;
        }
    }

    .buttonSecondary {
        @include button-secondary;

        margin-bottom: 3rem;
    }
    .buttonCTA {
        @include button-cta;
    }

    .arrowUp {
        transform: rotate(180deg);
    }

    .arrowDown {
        display: inline-block;
        width: 0;
        height: 0;
        border-left: .5rem solid transparent;
        border-right: .5rem solid transparent;
        border-top: 1rem solid $ButtonSecondary__color;
        transition: .2s ease;

        .buttonSecondary:hover &, .buttonSecondary:focus & {
            border-top-color: $ButtonSecondary__background-color;
        }
    }

    .button {
        @include button-primary;
    }

    .seperator {
        border: none;
        display: inline-block; // Prevent margins collapsing
        height: 1rem;
        position: relative;
        width: 100%;
        margin: 3.0rem 0;

        &:before {
            border-top: 1px solid $color-border;
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            width: 100%;
        }
    }
</style>
