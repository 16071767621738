import Vue from 'vue';
import TrademarkParser from '../helpers/TrademarkParser';
import { ImageSearchType } from '../enums/ImageSearchType';

export const setPage = (state, page) => {
    state.page = page;
};

export const setPageSize = (state, size) => {
    state.pageSize = size;
};

export const setAppSize = (state, appSize) => {
    state.appSize = appSize;
};

export const setAdvancedSearchVisible = (state, visible) => {
    state.advancedSearchVisible = visible;
};

export const setCount = (state, count) => {
    state.count = count;
};

export const setQuery = (state, query) => {
    state.query = query;
};

export const setOffice = (state, office) => {
    state.office = office;
};

export const resetOffice = (state) => {
    state.office = ['sxm', 'int'];
};

export const setView = (state, view) => {
    state.view = view;
};

export const setSearched = (state, searched) => {
    state.searched = searched;
};

export const setSearching = (state, searching) => {
    state.searching = searching;
};

export const addAdvancedSearchField = (state, field) => {
    if (!state.fields.includes(field)) {
        state.fields.push(field);
    }
};

export const setAdvancedSearchFields = (state, fields) => {
    state.fields = fields;
};

export const setImageMode = (state, mode) => {
    state.imageMode = mode;
};

export const removeAdvancedSearchField = (state, name) => {
    state.fields = state.fields.filter(field => field !== name);
};

export const setTrademarks = (state, trademarks) => {
    state.trademarks = trademarks.reduce((acc, item) => {
        const trademark = TrademarkParser.parse(item);

        acc[trademark.tenantId] = { ...acc[trademark.tenantId], ...trademark };

        return acc;
    }, {});

    state.trademarkList = trademarks.map(item => TrademarkParser.retrieveId(item));
};

export const setTrademark = (state, trademark) => {
    const id = TrademarkParser.retrieveId(trademark);
    state.trademarks = {
        ...state.trademarks,
        [id]: {
            ...state.trademarks[id],
            ...trademark,
        },
    };
};

export const setAdvancedSearchFieldValue = (state, data) => {
    Vue.set(state.advancedSearch, data.field, data.value);
};

export const removeAdvancedSearchFieldValue = (state, name) => {
    delete state.advancedSearch[name];
};

export const setMutationSearchFieldValue = (state, data) => {
    Vue.set(state.mutationSearch, data.field, data.value);
};

export const setMutationKinds = (state, mutationKinds) => {
    state.mutationKinds = mutationKinds;
};

export const setSearchingError = (state, error) => {
    state.searchingError = error;
};

export const resetMutationSearch = (state) => {
    state.mutationSearch = {};
};

export const resetAdvancedSearch = (state) => {
    const advancedSearch = {
        exact: true,
        goods: '',
        imageSearchType: ImageSearchType.COMBINED,
    };

    state.advancedSearch = {
        word: '',
        ...advancedSearch,
    };
    state.fields = ['word', 'goods'];
};

export const setOfficeDecisionsFields = (state, fields) => {
    state.officeDecisionsFields = fields;
};

export const setOfficeDecisionsField = (state, data) => {
    Vue.set(state.officeDecisions, data.field, data.value);
};

export const removeOfficeDecisionsField = (state, name) => {
    state.officeDecisionsFields = state.officeDecisionsFields.filter(field => field !== name);
};

export const removeOfficeDecisionsFieldValue = (state, name) => {
    delete state.officeDecisions[name];
};

export const setSearchedWithImage = (state, searchedWithImage) => {
    state.searchedWithImage = searchedWithImage;
};
