<template>
    <select :id="id" :value="value" :class="$style.input" @input="$emit('input', $event.target.value)">
        <option v-for="option in options" :key="option.value" :value="option.value">
            {{ option.label }}
        </option>
    </select>
</template>

<script>
    import Forms from '@/mixins/forms';

    export default {
        mixins: [Forms],

        props: {
            value: {
                type: String,
                default: '',
            },
        },

        computed: {
            options() {
                return [
                    { value: 'Individual', label: this.$t('search.trademark_kind.individual') },
                    { value: 'Certification', label: this.$t('search.trademark_kind.certification') },
                    { value: 'Collective', label: this.$t('search.trademark_kind.collective') },
                    { value: 'Collective_old', label: this.$t('search.trademark_kind.collective_old') },
                ];
            },
        },
    };
</script>

<style module lang="scss">
    @include formGroup;
    @include input {
        padding: .8rem 1rem;
    };
</style>
