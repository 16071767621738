<template>
    <div>
        <div :class="$style.advancedSearchLinkContainer">
            <div :class="$style.buttonLink" @click="goToAdvancedSearch()">
                {{ $t('search.advancedSearch') }}
            </div>
        </div>

        <div :class="$style.simpleSearchWrapper">
            <div :class="$style.simpleSearchInput">
                <SearchForm />
            </div>
        </div>

        <Loading />

        <SearchErrors />

        <SearchResults />
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import SearchForm from '@/components/SearchForm.vue';
    import Loading from '@/components/loading/Loading.vue';
    import SearchResults from '@/components/SearchResults';
    import SearchErrors from '@/components/SearchErrors';
    import { resetSearchResults } from '../store/actions';

    export default {
        name: 'SimpleSearch',

        components: {
            SearchResults,
            SearchForm,
            Loading,
            SearchErrors,
        },

        created() {
            window.addEventListener('beforeunload', this.beforeUnload);
        },

        destroyed() {
            window.removeEventListener('beforeunload', this.beforeUnload);
        },

        beforeRouteEnter(to, from, next) {
            const lastVisit = window.sessionStorage.getItem('last_visit');
            if ((lastVisit && Date.now() - parseInt(lastVisit) < 3000) || from.name === 'item') {
                next();
                return;
            }

            window.sessionStorage.removeItem('last_visit');
            next(vm => {
                vm.$nextTick(function () {
                    this.resetSearchResults();
                });
            });
        },

        beforeRouteLeave(to, from, next) {
            if (to.name !== 'item') {
                window.sessionStorage.removeItem('last_visit');
            }

            next();
        },

        methods: {
            ...mapActions(['resetSearchResults', 'resetAdvancedSearch']),
            goToAdvancedSearch() {
                // When we switch to advanced search, we want to reset the search results...
                this.resetSearchResults();
                this.resetAdvancedSearch();
                this.$router.push('/advanced-search');
            },
            beforeUnload(e) {
                window.sessionStorage.setItem('last_visit', Date.now().toString());
            },
        },
    };
</script>

<style module lang="scss">
    .simpleSearchWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .simpleSearchInput {
        flex: 1;
    }

    .searchHeader {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 6.8rem;

        h2 {
            color: $color-primary;
        }
    }

    .buttonLink {
        @include button-link();
    }

    .advancedSearchLinkContainer {
        text-align: right;
        margin-bottom: 2.4rem;
    }
</style>
