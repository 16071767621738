<template>
    <div style="width: 100%;">
        <input v-model.trim="field" type="text" :class="$style.input" @keydown.enter.prevent="addTerm" @blur="addTerm()">

        <div :class="$style.wrapper">
            <div v-for="(term, index) in fields" :key="index" :class="$style.term">
                {{ term }}

                <button type="button" :class="$style.remove" @click="remove(index)">
                    <span>X</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import Forms from '@/mixins/forms';

    export default {
        mixins: [Forms],

        props: {
            value: {
                type: String,
                default: '',
            },
        },

        data() {
            return {
                field: '',
                fields: [],
            };
        },

        mounted() {
            if (this.$store.state.advancedSearch.goods && this.$store.state.advancedSearch.goods !== '') {
                this.fields = this.$store.state.advancedSearch.goods.split(',');
            }
        },

        methods: {
            addTerm() {
                if (this.field) {
                    let terms = this.field.split(/[ ,]+/);

                    if (terms.length > 0) {
                        terms.forEach(term => {
                            this.fields.push(term);
                        });
                    }

                    this.field = '';

                    this.$emit('input', this.fields.join(','));
                }
            },

            remove(index) {
                this.fields.splice(index, 1);

                this.$emit('input', this.fields.join(','));
            },
        },
    };
</script>

<style module lang="scss">
    @include input();

    .term {
        background-color: $Select__term__background-color;
        border: .1rem solid $color-primary;
        display: inline-block;
        font-size: 1.3rem;
        line-height: 2rem;
        padding: .15rem .85rem;
        margin: 1rem 1rem 0 0;
        user-select: none;
        word-break: break-all;
    }

    .remove {
        @include close() {
            width: 1rem;
            height: 1rem;
            margin-left: 1rem;
            margin-top: -2px;
        };
    }
</style>
