import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages from './messages';

Vue.use(VueI18n);

const locale = process.env.VUE_APP_LANGUAGE || document.documentElement.lang;

let i18n = new VueI18n({
    locale,
    messages,
});

export default i18n;

if (module.hot) {
    module.hot.accept(['./messages'], () => {
        const messages = require('./messages').default;
        Object.keys(messages).forEach(locale => {
            i18n.setLocaleMessage(locale, messages[locale]);
        });
    });
}
