<template>
    <section :class="[$style.popupWindow, $style[$store.state.appSize]]">
        <button type="button" :class="$style.popupWindowClose" @click="$emit('close')">
            <span :class="$style.visuallyHidden">X</span>
        </button>

        <h1 :class="$style.popupWindowHeader">
            {{ $t('advanced_search.header') }}
        </h1>

        <div :class="$style.popupSectionWrapper">
            <div :class="$style.popupSectionColumn">
                <div :class="$style.popupSection">
                    <h2 :class="$style.popupSectionHeader">
                        {{ $t('advanced_search.brand_information') }}
                    </h2>
                    <div v-for="item in brandInformation" :key="item" :class="$style.checkbox">
                        <input :id="'advancedsearch_' + item" v-model="fields" type="checkbox" :value="item" :class="$style.checkbox__input">
                        <label :for="'advancedsearch_' + item" :class="$style.checkbox__label">{{ $t('search.fields.' + item) }}</label>
                    </div>
                    <HelpText :class="$style.helpText" :text="$t('advanced_search.help.brand_information')" />
                </div>
            </div>

            <div :class="$style.popupSectionColumn">
                <div :class="$style.popupSection">
                    <h2 :class="$style.popupSectionHeader">
                        {{ $t('advanced_search.data') }}
                    </h2>
                    <div v-for="item in data" :key="item" :class="$style.checkbox">
                        <input :id="'advancedsearch_' + item" v-model="fields" type="checkbox" :value="item" :class="$style.checkbox__input">
                        <label :for="'advancedsearch_' + item" :class="$style.checkbox__label">{{ $t('search.fields.' + item) }}</label>
                    </div>
                    <HelpText :class="$style.helpText" :text="$t('advanced_search.help.data')" />
                </div>

                <div :class="$style.popupSection">
                    <h2 :class="$style.popupSectionHeader">
                        {{ $t('advanced_search.holder') }}
                    </h2>
                    <div v-for="item in holder" :key="item" :class="$style.checkbox">
                        <input :id="'advancedsearch_' + item" v-model="fields" type="checkbox" :value="item" :class="$style.checkbox__input">
                        <label :for="'advancedsearch_' + item" :class="$style.checkbox__label">{{ $t('search.fields.' + item) }}</label>
                    </div>
                    <HelpText :class="$style.helpText" :text="$t('advanced_search.help.holder')" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { mapActions } from 'vuex';
    import HelpText from '../HelpText';

    export default {
        name: 'AdvancedSearchPopup',

        components: { HelpText },

        data() {
            return {
                brandInformation: [
                    'word',
                    'goods',
                    'nice',
                    'imageMark',
                    'registration',
                ],

                data: [
                    'depotDate',
                    'publicationDate',
                    'renewalDate',
                ],

                holder: [
                    'holder',
                ],
            };
        },

        computed: {
            fields: {
                get() {
                    return this.$store.state.fields;
                },

                set(value) {
                    this.setAdvancedSearchFields(value);
                },
            },
        },

        methods: {
            ...mapActions([
                'setAdvancedSearchFields',
            ]),
        },
    };
</script>

<style module lang="scss">
    .popupWindow {
        background-color: $AdvancedSearchPopup__popupWindow__background-color;
        padding: 2.4rem;
        position: relative;
        margin-top: 3rem;

        .popupWindowHeader {
            color: $color-primary;
            font-size: 2.2rem;
            font-weight: 600;
            line-height: 3.4rem;
            margin-bottom: 2.1rem;
            margin-right: 5rem;
        }
    }

    .popupWindowClose {
        @include close() {
            position: absolute;
            top: 2.4rem;
            right: 2.4rem;
        };
    }

    .popupSectionWrapper {
        .app-size-large & {
            display: flex;
            justify-content: space-between;
        }
    }

    .popupSectionColumn {
        display: flex;
        flex-direction: column;
        width: 100%;

        .app-size-large & {
            padding: 0 2.4rem 0 0;
        }

        &:last-child {
            padding-right: 0;
        }
    }

    .popupSection {
        width: 100%;
        height: 100%;
        background-color: $AdvancedSearchPopup__popupSection__background-color;
        margin-bottom: 2.4rem;
        position: relative;
        padding: 1.9rem 1.6rem 3.6rem;

        .popupSectionHeader {
            color: $AdvancedSearchPopup__popupSectionHeader__color;
            font-size: 1.4rem;
            font-weight: 600;
            line-height: 2.0rem;
        }

        &.checkbox:last-child {
            margin-bottom: 8rem;
        }
    }

    .helpText {
        position: absolute;
        right: 1.6rem;
        bottom: 1.6rem;
    }

    .addButtonContainer {
        text-align: right;
    }

    .addButton {
        @include button-primary;
    }

    @include checkbox() {
        margin-bottom: 1.2rem;
    };
</style>
