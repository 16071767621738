export default class AddressList {
    public city: string;
    public country: string;
    public countryCode: string;
    public extension: string;
    public number: string;
    public postOfficeBox: string;
    public state: string;
    public street: string;
    public zipCode: string;
    public houseNumber: string;
    public houseNumberExtension: string;
}
