import i18n from '../i18n';
import axios from 'axios';
import moment from 'moment';
import { load } from 'recaptcha-v3';
import { QueryType } from '../enums/QueryType';
import { QueryEndpoint } from '../enums/QueryEndpoint';
import * as Sentry from '@sentry/vue';

const recaptchaLoader = load(process.env.VUE_APP_API_RECAPTCHA_KEY);
const apiFormat = 'YYYY-MM-DD';
const dutchFormat = 'DD-MM-YYYY';

let previousSearchSource = null;

function getRecaptchaToken(action) {
    return recaptchaLoader.then(recaptcha => recaptcha.execute(action));
}

async function getTrademarks(context, parameters, errorMessage) {
    queryEndpoint(QueryEndpoint.TRADEMARKS, context, parameters, errorMessage);
}

async function getMutations(context, parameters, errorMessage) {
    queryEndpoint(QueryEndpoint.MUTATIONS, context, parameters, errorMessage);
}

async function queryEndpoint(endPoint, context, parameters, errorMessage) {
    if (previousSearchSource) {
        previousSearchSource.cancel();
        previousSearchSource = null;
    }

    let shouldScrollToResults = false;

    context.commit('setSearchingError', null);
    context.commit('setSearching', true);

    // Reset searched to let the user see a loader...
    context.commit('setSearched', false);

    previousSearchSource = axios.CancelToken.source();

    const token = await getRecaptchaToken('search');
    try {
        let response;

        if (endPoint === QueryEndpoint.TRADEMARKS) {
            response = await queryTrademarks(context, parameters, token);
        } else {
            response = await queryMutations(parameters, token);
            context.commit('setSearchedWithImage', false);
        }

        context.commit('setCount', response.data.totalCount);
        context.commit('setPageSize', response.data.pageSize);
        context.commit('setTrademarks', response.data.data || []);
        context.commit('setSearched', true);
        context.commit('setSearching', false);

        shouldScrollToResults = true;
    } catch (error) {
        if (axios.isCancel(error)) {
            return;
        }

        context.commit('setCount', 0);
        context.commit('setSearched', false);
        context.commit('setSearching', false);
        context.commit('setTrademarks', []);

        if (!error.response) {
            const translatedErrorMessage = i18n.t('search.serverError');
            Sentry.captureMessage(translatedErrorMessage.toString());
            context.commit('setSearchingError', translatedErrorMessage);
            return;
        }

        if (error.response.data && error.response.data.message && error.response.data.message.includes('one filter is required')) {
            Sentry.captureMessage(error.response.data.message);
            context.commit('setSearchingError', i18n.t('search.searchingErrorAtLeastOneFilter'));
        } else {
            const translatedErrorMessage = errorMessage || i18n.t('search.searchingError');
            Sentry.captureMessage(translatedErrorMessage.toString());
            context.commit('setSearchingError', translatedErrorMessage);
        }
    } finally {
        if (shouldScrollToResults) {
            setTimeout(() => {
                let element = document.getElementById('search-results');

                if (element) {
                    window.scroll({
                        behavior: 'smooth',
                        top: element.offsetTop + 285,
                    });
                }
            }, 10);
        }
    }
}

async function queryTrademarks(context, parameters, token) {
    if (parameters.data) {
        const response = await axios.post(
            `${process.env.VUE_APP_API_BASE_URL}trademarks/imageSearch`,
            parameters,
            {
                headers: {
                    'X-Recaptcha-Response': token,
                },
                cancelToken: previousSearchSource.token,
            }
        );
        context.commit('setSearchedWithImage', true);

        return response;
    }

    const response = axios.get(
        `${process.env.VUE_APP_API_BASE_URL}trademarks`,
        {
            headers: {
                'X-Recaptcha-Response': token,
            },
            params: parameters,
            cancelToken: previousSearchSource.token,
        }
    );

    context.commit('setSearchedWithImage', false);

    return response;
}

async function queryMutations(parameters, token) {
    try {
        return axios.get(
            `${process.env.VUE_APP_API_BASE_URL}mutation`,
            {
                headers: {
                    'X-Recaptcha-Response': token,
                },
                params: parameters,
                cancelToken: previousSearchSource.token,
            }
        );
    } catch (error) {
        Sentry.captureException(error);
    }
}

export const advancedSearch = (context, data) => {
    let parameters = { ...data };

    for (const [key, value] of Object.entries(parameters)) {
        if (key.includes('Date') && value) {
            delete parameters[key];

            parameters[`${key}From`] = value.from ? moment(value.from, dutchFormat).format(apiFormat) : null;
            parameters[`${key}To`] = value.to ? moment(value.to, dutchFormat).format(apiFormat) : null;
        }

        if (key.includes('nice')) {
            let goods = '';
            let nice = '';

            if (parameters['goods']) {
                goods = parameters['goods'].split(',');
            }

            if (parameters['nice']) {
                goods = parameters['nice'].split(',');
            }

            parameters['goods'] = goods.concat(nice).filter(el => el).join(',');

            delete parameters[key];
        }
    }

    if (parameters.refusalReason) {
        parameters.refusalReason = parameters.refusalReason.map(absoluteGround => {
            return absoluteGround.value;
        }).join(',');
    }

    if (parameters.wordOrImage && parameters.wordOrImage.type === QueryType.IMAGE) {
        const model = parameters.imageSearchType;
        delete parameters['imageSearchType'];
        const data = parameters.wordOrImage.value.split(',')[1];
        delete parameters['wordOrImage'];
        delete parameters['exact'];
        delete parameters['word'];

        getTrademarks(
            context,
            {
                ...parameters,
                model: model,
                data: data,
                page: context.state.page,
                office: context.state.office.join(',') || null,
            }
        ).catch(error => {
            Sentry.captureException(error);
        });

        return;
    }

    if (parameters.wordOrImage) {
        const value = parameters.wordOrImage.value;
        delete parameters['wordOrImage'];
        parameters.word = value;
    }
    delete parameters['imageSearchType'];

    getTrademarks(context, {
        ...parameters,
        page: context.state.page,
        office: context.state.office.join(',') || null,
    }).catch(error => {
        Sentry.captureException(error);
    });
};

export const mutationSearch = (context) => {
    let mutationPublicationFrom = '';
    let mutationPublicationTo = '';

    if (context.state.mutationSearch.hasOwnProperty('publicationDateMutation')) {
        if (context.state.mutationSearch.publicationDateMutation.from) {
            mutationPublicationFrom = moment(context.state.mutationSearch.publicationDateMutation.from, dutchFormat).format(apiFormat);
        }
        if (context.state.mutationSearch.publicationDateMutation.to) {
            mutationPublicationTo = moment(context.state.mutationSearch.publicationDateMutation.to, dutchFormat).format(apiFormat);
        }
    }

    const parameters = {
        page: context.state.page,
        mutationNumber: context.state.mutationSearch.mutationNumber || null,
    };

    const searchingError = i18n.t('mutation.searchingError');
    getTrademarks(context, {
        ...parameters,
        mutationType: context.state.mutationSearch.mutationType || null,
        mutationPublicationFrom: mutationPublicationFrom || null,
        mutationPublicationTo: mutationPublicationTo || null,
    }, searchingError).catch(error => {
        Sentry.captureException(error);
    });
};

export const search = (context) => {
    getTrademarks(context, {
        simpleSearch: context.state.query,
        page: context.state.page,
        office: context.state.office.join(',') || null,
    }).catch(error => {
        Sentry.captureException(error);
    });
};

export const fetchItem = async(context, id) => {
    const token = await getRecaptchaToken('view_item');

    let trademark;

    try {
        const response = await axios.get(
            `${process.env.VUE_APP_API_BASE_URL}trademarks/${id}`,
            {
                headers: {
                    'X-Recaptcha-Response': token,
                },
            }
        );

        trademark = response.data.data[0];

        context.commit('setTrademark', trademark);
    } catch (error) {
        if (error.message !== '404 null') {
            Sentry.captureException(error);
        }
    }
};

export const fetchMutationItem = async(context, id) => {
    let url = `${process.env.VUE_APP_API_BASE_URL}trademarks?mutationNumber=${id}`;

    try {
        const token = await getRecaptchaToken('view_mutation_item');
        const response = await axios.get(
            url,
            {
                headers: {
                    'X-Recaptcha-Response': token,
                },
            }
        );

        context.commit('setTrademark', response.data.data[0]);
    } catch (error) {
        Sentry.captureException(error);
    }
};

export const setOffice = (context, office) => {
    context.commit('setOffice', office);
    context.dispatch('search');
};

export const setAdvancedOffice = (context, office) => {
    context.commit('setOffice', office);
    context.dispatch('advancedSearch', context.state.advancedSearch);
};

export const setOfficeDecisionsOffice = (context, office) => {
    context.commit('setOffice', office);
    context.dispatch('advancedSearch', context.state.officeDecisions);
};

export const setAppSize = (context, appSize) => {
    context.commit('setAppSize', appSize);
};

export const removeAdvancedSearchField = (context, name) => {
    context.commit('removeAdvancedSearchFieldValue', name);
    context.commit('removeAdvancedSearchField', name);
};

export const setAdvancedSearchFields = (context, fields) => {
    const addedFields = fields.filter(el => !context.state.fields.includes(el));
    const removedFields = context.state.fields.filter(el => !fields.includes(el));

    addedFields.forEach(field => {
        context.commit('setAdvancedSearchFieldValue', { field, value: undefined });
    });

    removedFields.forEach(field => {
        context.commit('removeAdvancedSearchFieldValue', field);
    });

    context.commit('setAdvancedSearchFields', fields);
};

export const addAdvancedSearchFields = (context, fields) => {
    fields.forEach(field => context.commit('addAdvancedSearchField', field));
};

export const setAdvancedSearchFieldValue = (context, data) => {
    context.commit('setAdvancedSearchFieldValue', data);
};

export const fetchMutationKinds = async(context) => {
    try {
        const response = await axios.get(
            `${process.env.VUE_APP_API_BASE_URL}mutationKinds`,
        );

        let mutationKinds = response.data;
        mutationKinds.unshift('');
        context.commit('setMutationKinds', mutationKinds);
    } catch (error) {
        Sentry.captureException(error);
    }
};

export const resetSearchResults = context => {
    context.commit('setQuery', '');
    context.commit('setSearched', false);
    context.commit('setSearchingError', null);
    context.commit('setCount', 0);
    context.commit('setPageSize', 0);
    context.commit('setTrademarks', []);
};

export const resetAdvancedSearch = context => {
    context.commit('resetAdvancedSearch');
};

export const resetMutationSearch = context => {
    context.commit('resetMutationSearch');
};

export const orderCopy = async(context, data) => {
    const token = await getRecaptchaToken('order_copy');

    try {
        return axios.post(
            `${process.env.VUE_APP_BASE_URL}order-copy/order`,
            data,
            {
                headers: {
                    'X-Recaptcha-Response': token,
                },
            }
        );
    } catch (error) {
        Sentry.captureException(error);
    }
};

export const navigateToOtherSectionInAdvancedSearch = ({ commit, dispatch }) => {
    commit('resetAdvancedSearch');
    commit('resetMutationSearch');

    dispatch('resetSearchResults');
};

export const setOfficeDecisionsFields = ({ commit }, fields) => {
    commit('setOfficeDecisionsFields', fields);
};

export const setOfficeDecisionsField = ({ commit }, data) => {
    commit('setOfficeDecisionsField', data);
};

export const removeOfficeDecisionsField = ({ commit }, field) => {
    commit('removeOfficeDecisionsFieldValue', field);
    commit('removeOfficeDecisionsField', field);
};
