



















import { VPopover } from 'v-tooltip';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    components: {
        VPopover,
    },
})
export default class HelpText extends Vue {
    @Prop() text!: string;

    @Prop({ default: 'click hover' })
    trigger!: string;

    get sanitizedHtml() {
        const temp = document.createElement('div');
        temp.innerHTML = this.text;

        return temp.innerHTML.replace('\n', '<br>');
    }
}
