import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';

@Component
export default class Forms extends Vue {
    @Prop({ default: '' }) name: string;

    @Action removeAdvancedSearchField;
    @Action setAdvancedSearchFieldValue;

    get id() {
        return `bip-register-${this.name}`;
    }
}
