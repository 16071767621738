import GoodsAndServices from '@/models/GoodsAndServices';
import PreviousRights from '@/models/PreviousRights';
import ViennaCodeClassification from '@/models/ViennaCodeClassification';
import Person from '@/models/Person';
import Representation from '@/models/Representation';
import Recordal from '@/models/Recordal';
import AcquiredRight from '@/models/AcquiredRight';
import TrademarkImages from '@/models/TrademarkImages';
import nameAndAddress from '@/models/NameAndAddress';
import MarksCommission from '@/models/MarksCommission';

export default class Trademark {
    constructor(args: any = {}) {
        Object.assign(this, args);
    }

    public tenantId: string;

    public registrationNumber: string = '';
    public depotDateTime: string = '';
    public depotDate: string = '';
    public expirationDate: string = '';
    public registrationDate: string = '';
    public name: string = '';
    public kind: string = '';
    public office: string = '';
    public feature: string = '';
    public status: string = '';
    public basis: string = '';
    public goodsAndServices: GoodsAndServices;
    public previousRights: PreviousRights;
    public viennaCodeClassification: ViennaCodeClassification;
    public applicants?: Array<Person>;
    public representative: Person;
    public acquiredRights?: Array<AcquiredRight>;
    public images?: TrademarkImages;
    public marksCommission?: MarksCommission;

    // Benelux...
    public applicationNumber: string = '';
    public filingDate: string = '';
    public publicationDateFiling: string = '';
    public filingDateRenewal: string = '';
    public publicationDateRenewal: string = '';
    public acceleratedPublicationDate: string = '';
    public oppositionStartDate: string = '';
    public oppositionEndDate: string = '';
    public publicationDateRegistration: string = '';
    public representation: Representation | null;
    public recordals: Array<Recordal> = []; // Will be set in actions.ts
    public disclaimerAcceleratedRegistration: string = '';
    public seniorities: Array<string> = [];
    public mutationNumber: string = '';
    public priorityDate: string = '';
    public statuses: Array<string> = [];
    public definitiveRefusalResult: string = '';
    public definitiveRefusalDate: string = '';
    public absoluteGrounds: Array<{text: string}> = [];
    public headerAlreadyPresentInTextToShow: string = '';

    // TODO: Check types...
    public renewal: any;
    public priorities: any;
    public publications: any;
    public correspondenceAddress: any;
    public mutations: any;

    public pledges: Array<{}> = [];
    public licenses: Array<{}> = [];
    public writAttachments: Array<{}> = [];

    // Mutation
    public headingText?: string;
    public publicationDate?: string;
    public applicationDate: string = '';
    public contacts?: Array<any>;
    public trademarkList?: Array<any>;
    public trademarkListFull?: Array<Trademark>;
    public nameAddressData?: Array<nameAndAddress>;
    public comment?: string
    public goodsAndServicesComment?: string
    get thumbnail(): string {
        if (!this.images) return '';

        return this.images.thumbnail;
    }

    get mediumImage(): string {
        if (!this.images) return '';

        return this.images.medium;
    }

    get fullImage(): string {
        if (!this.images) return '';

        return this.images.full;
    }

    get generateStatus(): string {
        if (this.basis === 'IRTransformation') {
            return 'IRTransformation';
        }

        if (this.basis === 'EUConversion') {
            return 'EUConversion';
        }

        return this.office;
    }
}
