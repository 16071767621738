











import { Vue, Component, Prop } from 'vue-property-decorator';
import Trademark from '@/models/Trademark';

@Component
export default class TrademarkHeader extends Vue {
    @Prop({ required: true })
    trademark!: Trademark;
}
