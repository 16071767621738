import Vue from 'vue';
import Router from 'vue-router';
import routes from './routes';

Vue.use(Router);

export function setupHistory(router) {
    const startLocation = getLocation(router);

    let historyIndex = router.history.index;
    router.afterEach((to) => {
        const newHistoryIndex = router.history.index;

        const url = getUrl(to.fullPath);

        // Detect if the route change was pushed or replaced.
        if (newHistoryIndex === historyIndex) {
            const state = window.history.state;
            window.history.replaceState(state, '', url);
        } else {
            historyIndex = newHistoryIndex;
            window.history.pushState({}, '', url);
        }
    });

    const setupListeners = () => {
        const handleRoutingEvent = () => {
            router.history.transitionTo(getLocation(router));
        };

        window.addEventListener('popstate', handleRoutingEvent);
    };

    // Initialize start location.
    router.history.transitionTo(startLocation, setupListeners, setupListeners);
}

function getLocation(router) {
    const url = new URL(window.location.href);

    const app = url.searchParams.get('app');
    if (app) {
        return app;
    }

    const hash = url.hash.replace('#', '');
    if (hash && router.match(hash).matched.length) {
        return hash;
    }

    return '/';
}

function getUrl(location) {
    const url = new URL(window.location.href);
    const hash = url.hash.replace('#', '');
    url.searchParams.set('app', location);
    if (hash === location) {
        url.hash = '';
    }
    return url.toString();
}

export default new Router({
    routes,
    mode: 'abstract',
});
