export default {
    forms: {
        date: {
            from: 'van',
            to: 'tot',
        },
    },

    print: {
        header: 'Handelsmerken Register Sint Maarten',
    },

    search: {
        advancedSearch: 'Geavanceerd zoeken',
        exact: 'Exacte zoekterm',
        filter: 'Toon',
        filter_add: 'Voeg zoekfilter toe',
        filter_help: 'Met beide vinkjes aan, ziet u in de resultaten zowel BES merken als internationale merken met aanduiding Bonaire, Sint-Eustatius en Saba (BQ).\nWilt u enkel BES merken zien in de resultaten? Vink dan INT uit.\nWilt u enkel internationale inschrijvingen met aanduiding BQ zien? Vink dan BES uit.',
        no_results: 'Er zijn geen zoekresultaten gevonden, probeer het opnieuw.',
        search_results: 'Zoekresultaten',
        searchingError: 'Er is een fout opgetreden tijdens het zoeken, probeer het later opnieuw.',
        searchingFor: 'Ik zoek op',
        searchingForBrand: 'Merken',
        searchingForMutation: 'Mutaties',
        searchingErrorAtLeastOneFilter: 'Gebruik tenminste een filter.',
        simpleSearch: 'Eenvoudig zoeken',
        submit: 'Zoeken',
        submit_help: 'Zoek op inschrijvingsnummer of op cijfer- en lettercombinatie van een merk. Het zoekresultaat toont enkel gepubliceerde merken waarin de exacte zoekterm voorkomt, ongeacht de plaats in het merk. \nWilt u zoeken op gelijkende merken, gebruik dan \'Geavanceerd zoeken\' en zet het vinkje bij \'Exacte zoekterm\' uit.',
        term: 'Zoektermen',
        total: 'Totaal',
        itemNotFound: 'Uw zoekopdracht kan momenteel niet worden verwerkt.',
        itemNotFoundUrl: 'Probeer het hier opnieuw.',

        fields: {
            depotDate: 'Depotdatum',
            goods: 'Waren en diensten',
            holder: 'Houder',
            imageMark: 'Beeldmerkindeling',
            mutationNumber: 'Mutatienummer',
            mutationType: 'Mutatietype',
            nice: 'Nice classificatie',
            publicationDateMutation: 'Publicatiedatum mutatie',
            publicationDate: 'Publicatiedatum inschrijving',
            renewalDate: 'Publicatiedatum vernieuwing',
            registration: 'Inschrijvingsnummer',
            word: 'Woordelement',

            help: {
                depotDate: 'Voor het zoeken binnen een bepaalde periode geeft u een begin- en einddatum in.',
                goods: 'Zoek naar merken aan de hand van de producten en/of diensten waarvoor ze zijn aangevraagd. U zoekt op de termen in de taal die u gebruikt.',
                holder: 'Voor het zoeken van een merk van een bepaalde deposant (merkhouder) maakt u een keuze uit ons klantenbestand.',
                imageMark: 'Een beeldmerk (logo) kunt u opzoeken d.m.v. een toegekende code. Alle beeldmerken worden ingedeeld volgens een internationale standaard, de Wenen classificatie.',
                mutationNumber: 'Vul hier het nummer in van de mutatie die u zoekt',
                mutationType: 'Zoek naar mutaties van een bepaald type. \nU bent verplicht een mutatietype te kiezen.',
                nice: 'Een merk in een bepaalde producten- of dienstencategorie kunt u opzoeken door een selectie uit de Nice classificatie te maken. Een merkregistratie is altijd voor bepaalde producten en/of diensten. Deze waren en diensten zijn gerangschikt in klassen volgens een internationale standaard, de Nice classificatie. Zoek op nummer van de klasse.',
                publicationDateMutation: 'Zoek naar mutaties van een bepaald type die tijdens de gekozen periode werden gepubliceerd. \nU bent verplicht een publicatieperiode op te geven.',
                publicationDate: 'Voor het zoeken binnen een bepaalde periode geeft u een begin- en einddatum in.',
                renewalDate: 'Zoek naar BES merken die tijdens een bepaalde periode werden vernieuwd.',
                registration: 'Vul hier het inschrijvingsnummer in van het merk dat u zoekt.',
                word: 'Zoek aan de hand van de alfanumerieke elementen van een merk.\nWanneer \'Exacte zoekterm\' aangevinkt staat, krijgt u in de resultaten enkel gepubliceerde merken te zien waarin de exacte zoekterm voorkomt, ongeacht de plaats in het merk. Wilt u zoeken op gelijkende merken (waarvan een aantal karakters verschillen), zet dan het vinkje uit.',
            },
        },

        office: {
            sxm: 'SXM',
            int: 'INT',
        },
    },

    advanced_search: {
        add: 'Voeg toe',
        brand_information: 'Merk informatie',
        data: 'Data',
        header: 'Voeg hier 1 of meerdere zoektermen toe',
        holder: 'Houder',
        mutations: 'Mutaties',

        help: {
            brand_information: 'Zoek op basis van woordelementen, waren en diensten, beeldelementen of inschrijvingsnummer (of een combinatie van deze elementen) naar merken. \nU zoekt zowel binnen de BES-merken als de internationale merken met geldigheid in Caribisch Nederland.',
            data: 'Gebruik deze zoekvelden om te zoeken naar merken die binnen deze datumreeks vallen. \nU zoekt zowel binnen de BES-merken als de internationale merken met geldigheid in Caribisch Nederland.',
            holder: 'Gebruik de zoekterm "houder" om alle merken van een bepaalde merkhouder te vinden.',
            mutations: 'Gebruik deze zoekvelden om te zoeken in de mutaties van BES-merken. Deze zoekvelden kunnen niet gecombineerd worden met zoekvelden uit andere categorieën. \nIndien u zoekt op mutatietype, bent u verplicht een publicatiedatum van de mutatie op te geven.',
        },
    },

    item: {
        back: 'Terug naar zoekresultaten',
        orderCopy: 'Afschrift bestellen',
        print: 'Afdrukken',
        savePdf: 'Opslaan als PDF',
    },

    mutation: {
        depotNumbers: 'Inschrijvingsnummer(s)',
        description: 'Mutatie beschrijving',
        information: 'Mutatie informatie',
        number: 'Mutatienummer',
        publicationDate: 'Publicatiedatum',
        signedDate: 'Aantekendatum',
        status: 'Status',
        type: 'Mutatietype',
        searchingError: 'Er is een fout opgetreden. U kunt enkel zoeken op mutatienummer of op de combinatie mutatietype en publicatiedatum mutatie.',
    },

    trademark: {
        acquiredRights: 'Verkregen rechten in de Nederlandse Antillen',
        applicant: 'Houder',
        applicantDescription: 'Naam en adres van de houder',
        applicationDate: 'Indieningsdatum vernieuwingsverzoek',
        correspondenceAddress: 'Correspondentie adres',
        correspondenceAddressAndName: 'Naam en adres van de gemachtigde of het correspondentie-adres van de houder',
        depotDate: 'Dagtekening (dag en uur) van het depot',
        expirationDate: 'Vervaldatum',
        goodsAndServices: 'Waren en diensten',
        goodsAndServicesDescription: 'Opgave van de waren en diensten',
        goodsAndServicesPrefix: 'Kl',
        goodsAndServicesNumber: 'Klasse-opsomming',
        image: 'Weergave van het merk',
        kind: 'Soort',
        feature: 'Type',
        mark: 'Merk',
        markInformation: 'Merk informatie',
        moreinfo: 'Meer details',
        mutation: 'Mutatienummer',
        mutationDetails: 'Mutatiedetails',
        mutationRegistered: 'Datum aangetekend',
        mutations: 'Mutaties',
        mutationStatus: 'Status',
        mutationType: 'Mutatietype',
        publicationDateRegistration: 'Publicatiedatum van de inschrijving',
        publicationDateRenewal: 'Publicatiedatum van de vernieuwing',
        registrationDate: 'Inschrijvingsdatum',
        registrationNumber: 'Inschrijvingsnummer',
        representative: 'Gemachtigde',
        representativeDescription: 'Naam en adres van de gemachtigde',
        rightOfPriority: 'Recht van prioriteit',
        status: 'Status',
        viennaCodes: 'Classificatie van de beeldelementen, type merk, kleuren, onderscheidende elementen',
    },
};
