<template>
    <div style="flex: 1;">
        <input id="search_word" :value="value" type="text" size="15" maxlength="128" :class="$style.input" @input="$emit('input', $event.target.value)">
        <div v-if="showExact" :class="$style.checkbox">
            <input id="search_word_exact" v-model="exact" type="checkbox" :class="$style.checkbox__input">
            <label for="search_word_exact" :class="$style.checkbox__label">{{ $t('search.exact') }}</label>
        </div>
    </div>
</template>

<script>
    import Forms from '@/mixins/forms';

    export default {
        mixins: [Forms],

        props: {
            value: {
                type: String,
                default: '',
            },
            showExact: {
                type: Boolean,
                default: true,
            },
        },

        computed: {
            exact: {
                get() {
                    return this.$store.state.advancedSearch.exact;
                },
                set(value) {
                    this.$store.commit('setAdvancedSearchFieldValue', {
                        value: value,
                        field: 'exact',
                    });
                },
            },
        },
    };
</script>

<style module lang="scss">
    @include input;
    @include checkbox() {
        margin-top: 1.2rem;
    };
</style>
