<template>
    <div :class="$style.wrapper" style="width: 100%;">
        <VPopover
            auto-hide
            placement="auto"
            trigger="click"
            offset="10"
            :popover-class="$style.popover"
        >
            <input
                :id="'advancedSearch_' + name"
                :value="commaSeperatedValue"
                type="text"
                size="15"
                maxlength="128"
                :class="$style.input"
                class="trigger"
                readonly
            >

            <template #popover>
                <div
                    v-for="(number, index) in numbers"
                    :key="index"
                    :class="[$style.number, isSelected(number) ? $style.selected : '']"
                    @click="select(number)"
                >
                    {{ number }}
                </div>
            </template>
        </VPopover>
    </div>
</template>

<script>
    import Forms from '@/mixins/forms';
    import { VPopover } from 'v-tooltip';

    export default {
        components: {
            VPopover,
        },

        mixins: [Forms],

        props: {
            value: {
                type: String,
                default: '',
            },
        },

        data() {
            return {
                numbers: [],
                selected: [],
            };
        },

        computed: {
            commaSeperatedValue() {
                return this.selected.join(', ');
            },
        },

        mounted() {
            for (let i = 1; i <= 45; i++) {
                this.numbers.push(i);
            }

            if (this.$store.state.advancedSearch.nice && this.$store.state.advancedSearch.nice !== '') {
                this.$store.state.advancedSearch.nice.split(',').forEach(number => {
                    this.selected.push(parseInt(number));
                });
            }
        },

        methods: {
            select(number) {
                if (this.isSelected(number)) {
                    this.selected = this.selected.filter(selected => selected !== number);
                } else {
                    this.selected.push(number);
                }

                this.$emit('input', this.commaSeperatedValue.replace(/\s/g, ''));
            },

            isSelected(number) {
                return this.selected.includes(number);
            },
        },
    };
</script>

<style module lang="scss">
    @include input() {
        &:read-only {
            background: $Input__background-color;
        }
    };

    .wrapper {
        position: relative;
    }

    .popover {
        background-color: $Nice__popover__background-color;
        padding: .8rem;
        max-width: 336px;
        width: 100%;
    }

    .number {
        outline: 1px solid $Nice__number__border-color;
        border: 1px solid $Nice__number__border-color;
        font-size: 13px;
        cursor: pointer;
        display: inline-block;
        line-height: 3rem;
        text-align: center;
        width: 3.2rem;
        height: 3.2rem;
        background-color: $Input__background-color;
        user-select: none;

        &:hover {
            background-color: $color-secondary-variant;
            color: $color-secondary-variant-contrary;
        }

        &.selected {
            background-color: $color-secondary;
            color: $color-secondary-contrary;
        }
    }
</style>

<style>
    .trigger {
        width: 100%;
    }
</style>
