/**
 * When adding a new language, also update the src/assets/components/forms/Date.vue
 * component to ensure the component can handle the new language...
 */

const languages = {
    en: require(`./en`).default,
    nl: require(`./nl`).default,
};

export default languages;
