import { Vue, Component } from 'vue-property-decorator';

@Component
export default class Images extends Vue {
    get imageDefault() {
        // TODO: Figure out what the default image should be...
        return '';
    }

    get imageThumbnail() {
        if (this.trademark.trademarkListFull && this.trademark.trademarkListFull.length > 0) {
            return this.trademark.trademarkListFull[0].thumbnail;
        }

        return this.trademark.thumbnail;
    }

    get imageMedium() {
        if (this.trademark.trademarkListFull && this.trademark.trademarkListFull.length > 0) {
            return this.trademark.trademarkListFull[0].mediumImage;
        }

        return this.trademark.mediumImage;
    }

    get imageFull() {
        if (this.trademark.trademarkListFull && this.trademark.trademarkListFull.length > 0) {
            return this.trademark.trademarkListFull[0].fullImage;
        }

        return this.trademark.fullImage;
    }

    hasImage(size) {
        if (this.trademark.trademarkListFull && this.trademark.trademarkListFull.length > 0) {
            return this.trademark.trademarkListFull[0].images && this.trademark.trademarkListFull[0].images.hasOwnProperty(size);
        }

        return this.trademark.images && this.trademark.images.hasOwnProperty(size);
    }
}
