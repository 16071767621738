





















































import Dates from '../../mixins/dates';
import Redirect from '../../mixins/redirect';
import { Component, Prop } from 'vue-property-decorator';
import Trademark from '@/models/Trademark';
import { mixins } from 'vue-class-component';

@Component
export default class TrademarkTable extends mixins(Dates, Redirect) {
    @Prop() trademark!: Trademark;

    get niceClasses() {
        const niceClasses = [];

        if (this.trademark.goodsAndServices && this.trademark.goodsAndServices.niceClasses) {
            this.trademark.goodsAndServices.niceClasses.forEach(niceClass => {
                niceClasses.push(niceClass.number);
            });

            return niceClasses.sort((a, b) => a - b).join(', ');
        }

        return niceClasses;
    }
}
