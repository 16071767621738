












































import { Vue, Component } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import HelpText from '@/components/HelpText.vue';

@Component({
    components: {
        HelpText,
    },
})
export default class OfficeDecisionsSearchPopup extends Vue {
    brandInformation = [
        'word',
        'goods',
    ];

    data = [
        'depotDate',
    ];

    @Action setOfficeDecisionsFields;

    get officeDecisionsFields() {
        return this.$store.state.officeDecisionsFields;
    }

    set officeDecisionsFields(value) {
        this.setOfficeDecisionsFields(value);
    }
}
