import SimpleSearch from '../views/SimpleSearch.vue';
import Item from '../views/Item.vue';
import AdvancedSearch from '../views/AdvancedSearch';
import MutationsSearch from '../views/MutationsSearch';
import OrderCopy from '../views/OrderCopy';
import OrderCopySuccess from '../views/OrderCopySuccess';
import OfficeDecisionsSearch from '../views/OfficeDecisionsSearch';
import RouteNotFound from '../views/RouteNotFound';

export default [
    {
        path: '/',
        name: 'simple-search',
        component: SimpleSearch,
    },
    {
        path: '/item/:id',
        name: 'item',
        component: Item,
    },
    {
        path: '/advanced-search',
        name: 'advanced-search',
        component: AdvancedSearch,
    },
    {
        path: '/mutation-search',
        name: 'mutation-search',
        component: MutationsSearch,
    },
    {
        path: '/mutation-item/:id',
        name: 'mutation-item',
        component: Item,
    },
    {
        path: '/item/:id/order',
        name: 'item-order',
        props: true,
        component: OrderCopy,
    },
    {
        path: '/item/order/success',
        name: 'item-order-success',
        component: OrderCopySuccess,
    },
    {
        path: '/office-decisions-search',
        name: 'office-decisions-search',
        component: OfficeDecisionsSearch,
    },
    {
        path: '*',
        name: '404',
        component: RouteNotFound,
    },
];
