import Vue from 'vue';

// @ts-ignore
import VTooltip from 'v-tooltip';

// @ts-ignore
import PortalVue from 'portal-vue';

import App from './App.vue';
import i18n from './i18n';
import router, { setupHistory } from './router';
import store from './store';
import VModal from 'vue-js-modal';
import * as Sentry from '@sentry/vue';

if (process.env.VUE_APP_SENTRY_DSN) {
    Sentry.init({
        Vue,
        dsn: process.env.VUE_APP_SENTRY_DSN,
        environment: process.env.VUE_APP_SENTRY_ENVIRONMENT || 'dev',
    });
}

Vue.config.productionTip = false;

Vue.use(VTooltip, { popover: { defaultContainer: '#register-app' } });
Vue.use(PortalVue);
Vue.use(VModal);

let portalTarget = document.createElement('div');
portalTarget.id = 'register-app-print';
document.body.appendChild(portalTarget);

new Vue({
    i18n,
    router,
    store,
    beforeCreate: function () {
        setupHistory(this.$router);
    },
    render: h => h(App),
}).$mount('#register-app');
