<template>
    <div :class="[$style.wrapper, $style[$store.state.appSize]]" style="width: 100%;">
        <div :class="$style.group">
            <label>{{ $t('forms.date.from') }}</label>
            <datepicker
                v-model="from"
                :format="dateFormatter"
                :language="language"
                :monday-first="true"
                typeable
            >
                <template #afterDateInput>
                    <span class="animated-placeholder">
                        <CalendarIcon :class="$style.icon" />
                    </span>
                </template>
            </datepicker>
        </div>
        <div :class="$style.group">
            <label>{{ $t('forms.date.to') }}</label>
            <datepicker
                v-model="to"
                :format="dateFormatter"
                :language="language"
                :monday-first="true"
                :disabled-dates="{to: from}"
                typeable
            >
                <template #afterDateInput>
                    <span class="animated-placeholder">
                        <CalendarIcon :class="$style.icon" />
                    </span>
                </template>
            </datepicker>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import Datepicker from 'vuejs-datepicker';

    /**
     * Beware! When adding more languages, also edit the "language" computed property too
     * handle the newly added languages correctly. Otherwise the fallback will always be Dutch...
     */
    import { nl, en, fr } from 'vuejs-datepicker/dist/locale';

    import Base from '@/components/forms/Base.vue';
    import Forms from '@/mixins/forms';

    import CalendarIcon from '@/assets/icons/calendar.svg';

    /**
     * We overwrite the "parseTypedDate" and "inputBlurred" methods because in the original
     * methods they are parsing the date with the JavaScript Date object. When we do this with
     * the value "26-12-2018", both methods throw an error because the number 26 isn't a known month
     * too the JavaScript Date object. This is why we use moment (with an date format) in both methods...
     */

    Datepicker.components.DateInput.methods.parseTypedDate = function parseTypedDate(event) {
        // close calendar if escape or enter are pressed
        if ([ 27, 13 ].includes(event.keyCode)) { // escape and enter
            this.input.blur();
        }

        if (this.typeable) {
            let typedDate = moment(this.input.value, 'DD-MM-YYYY').toDate();
            if (!isNaN(typedDate)) {
                this.typedDate = this.input.value;
                this.$emit('typedDate', typedDate);
            }
        }
    };

    Datepicker.components.DateInput.methods.inputBlurred = function inputBlurred() {
        if (this.typeable && isNaN(moment(this.input.value, 'DD-MM-YYYY').toDate())) {
            this.clearDate();
            this.value = null;
            this.typedDate = null;
        }

        this.$emit('closeCalendar');
    };

    const dateFormat = 'DD-MM-YYYY';

    function dateFormatter(date) {
        return moment(date, 'YYYY-MM-DD').format(dateFormat);
    }

    export default {
        components: {
            CalendarIcon,
            Datepicker,
        },

        extends: Base,

        mixins: [Forms],

        props: {
            value: {
                type: Object,
                default() {
                    return {
                        from: null,
                        to: null,
                    };
                },
            },
        },

        computed: {
            language() {
                switch (this.$i18n.locale) {
                    case 'en':
                        return en;
                    case 'fr':
                        return fr;
                    default:
                        return nl;
                }
            },

            from: {
                get() {
                    return this.value.from ? moment(this.value.from, dateFormat).toDate() : null;
                },
                set(value) {
                    this.emitInput(value, this.to);
                },
            },

            to: {
                get() {
                    return this.value.to ? moment(this.value.to, dateFormat).toDate() : null;
                },
                set(value) {
                    this.emitInput(this.from, value);
                },
            },
        },

        mounted() {
            if (!this.value) {
                this.emitInput(null, null);
            }

            if (!this.value.from || !this.value.to) {
                this.emitInput(null, null);
            }
        },

        methods: {
            emitInput(from, to) {
                this.$emit('input', {
                    from: from ? dateFormatter(from) : null,
                    to: to ? dateFormatter(to) : null,
                });
            },

            dateFormatter,
        },
    };
</script>

<style module lang="scss">
    .wrapper {
        display: flex;
        flex-direction: column;

        input {
            border: .0625rem solid $Input__border-color;
            background-color: $Input__background-color;
            width: 100%;
        }

        input:read-only {
            background-color: $Input__background-color;
        }

        &.app-size-large, &.app-size-medium {
            flex-direction: row;
        }
    }

    .icon {
        fill: $color-primary;
        pointer-events: none;
        position: absolute;
        width: 2.4rem;
        height: 2.4rem;
        top: 1.5rem;
        right: 1.5rem;
    }

    .group {
        flex: 1;
        display: flex;
        align-items: center;

        .app-size-medium &, .app-size-large & {
            margin-right: 2.4rem;
        }

        &:last-child {
            margin-right: 0;
        }

        label {
            width: 5rem;
            display: block;
            margin-right: 1.2rem;
        }
    }
</style>

<style lang="scss">
    .vdp-datepicker {
        width: 100%;
    }

    .vdp-datepicker__calendar .cell.selected {
        background: $color-secondary;
        color: $color-secondary-contrary;

        &:hover {
            background: $color-secondary-variant;
            color: $color-secondary-variant-contrary;
        }
    }

    .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover,
    .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover,
    .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover {
        border: 1px solid $color-secondary-variant !important;
    }
</style>
