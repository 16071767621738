










import { Component, Prop, Vue } from 'vue-property-decorator';
import TrademarkParser from '@/helpers/TrademarkParser';
import GridListItem from '@/components/GridListItem.vue';

@Component({
    components: {
        GridListItem,
    },
})
export default class GridList extends Vue {
    @Prop({ required: true })
    trademarks!: [];

    convertToTrademarkClass(trademark: any) {
        return TrademarkParser.parse(trademark);
    }
}
