import { Vue, Component } from 'vue-property-decorator';

@Component
export default class Redirect extends Vue {
    urlToWipoRegister(registrationNumber) {
        return 'http://www.wipo.int/madrid/monitor/en/showData.jsp?ROM=1&ID=ROM.' + (registrationNumber || '').replace(/^0+/, '');
    }

    urlToEurRegister(number) {
        // Remove EUTM from the number is it present...
        number = number.replace('EUTM', '');

        return `https://euipo.europa.eu/eSearch/#details/trademarks/${number.padStart(9, '0')}`;
    }
}
