




















import { Vue, Component } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import SearchResults from '@/components/SearchResults.vue';
import SearchErrors from '@/components/SearchErrors.vue';
import AdvancedSearchTypeSwitcher from '@/components/AdvancedSearch/AdvancedSearchTypeSwitcher.vue';
import OfficeDecisionsSearchForm from '@/components/OfficeDecisionsSearchForm.vue';

@Component({
    components: {
        SearchResults,
        SearchErrors,
        AdvancedSearchTypeSwitcher,
        OfficeDecisionsSearchForm,
    },
})
export default class OfficeDecisionsSearch extends Vue {
    @Action resetSearchResults;
    @Action resetAdvancedSearch;

    goToSimpleSearch() {
        this.resetSearchResults();
        this.resetAdvancedSearch();
        this.$router.push('/');
    }
}
