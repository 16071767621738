let state = {
    advancedSearch: {
        exact: true,
        goods: '',
        words: '',
    },
    appSize: null,
    count: 0,
    expires: new Date().setHours(new Date().getHours() + 1),
    mutationKinds: [],
    mutationSearch: {},
    page: 1,
    pageSize: 10,
    searched: false,
    searching: false,
    searchingError: null,
    searchedWithImage: false,
    query: null,
    imageSearchFile: null,
    imageMode: false,
    trademarkList: [],
    trademarks: {},
    view: 'row',
    office: ['sxm', 'int'],
    fields: ['word', 'goods'],
};

export default state;
