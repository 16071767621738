import AddressList from '@/models/AddressList';

export default class CorrespondenceAddress {
    public readonly name: string;
    public readonly address: AddressList;

    constructor(name: string, address: AddressList) {
        this.name = name;
        this.address = address;
    }
}
