import Trademark from '@/models/Trademark';
import AddressList from '@/models/AddressList';
import TrademarkImages from '@/models/TrademarkImages';
import audioThumbnail from '@/assets/images/thumbnails/audio.png';
import videoThumbnail from '@/assets/images/thumbnails/video.png';
import hologramThumbnail from '@/assets/images/thumbnails/hologram.png';
import motionThumbnail from '@/assets/images/thumbnails/motion.png';
import CorrespondenceAddress from '@/models/CorrespondenceAddress';

export default class TrademarkParser {
    public static parse(data: any): Trademark {
        if (!data) return;

        const trademark = new Trademark(data);
        trademark.tenantId = trademark.registrationNumber ? trademark.registrationNumber : data.id;

        return trademark;
    }

    public static retrieveId(trademark: any): string {
        return trademark.registrationNumber ? trademark.registrationNumber : trademark.id;
    }

    public static convertSourceToCorrespondenceAddress(source: any): CorrespondenceAddress|null {
        if (source && source._source) {
            const data = source._source;

            let address = new AddressList();
            address.city = data.address.city;
            address.countryCode = data.address.countryCode;
            address.country = data.address.country;
            address.houseNumber = data.address.houseNumber;
            address.houseNumberExtension = data.address.houseNumberExtension;
            address.state = data.address.state;
            address.street = data.address.street;
            address.zipCode = data.address.zipCode;

            return new CorrespondenceAddress(data.registerNameAC, address);
        }

        return null;
    }

    public static convertRepresentationToImages(trademark: any): TrademarkImages {
        if (!trademark.representation) {
            return;
        }
        const imageUrl = process.env.VUE_APP_API_BASE_URL + 'document/image/' + trademark.representation.token;
        const trademarkType = trademark.type ? trademark.type.toLowerCase() : '';
        const contentType = trademark.representation.contentType.split('/')[0];

        const thumbnails = {
            sound: audioThumbnail,
            multimedia: videoThumbnail,
            hologram: hologramThumbnail,
            motion: motionThumbnail,
        };

        if (contentType === 'image') {
            return {
                thumbnail: imageUrl + '/thumbnail',
                medium: imageUrl + '/medium',
                full: imageUrl + '/full',
            };
        }

        return {
            thumbnail: thumbnails[trademarkType],
        };
    }
}
