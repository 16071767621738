<template>
    <div style="width: 100%;">
        <select :id="id" :value="value" :class="$style.input" @input="$emit('input', $event.target.value)">
            <option v-if="mutationKinds.length === 0" disabled selected>
                Laden...
            </option>
            <option v-for="(mutationKind, index) in mutationKinds" :key="index">
                {{ mutationKind }}
            </option>
        </select>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex';
    import Forms from '@/mixins/forms';

    export default {
        mixins: [Forms],

        props: {
            value: {
                type: String,
                default: '',
            },
        },

        computed: mapState(['mutationKinds']),

        mounted() {
            if (this.mutationKinds.length === 0) {
                this.fetchMutationKinds().then(() => this.$forceUpdate());
            }
        },

        methods: mapActions(['fetchMutationKinds']),
    };
</script>

<style module lang="scss">
    @include formGroup;
    @include input {
        padding: .8rem 1rem;
    };
</style>
