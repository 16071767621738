























import Forms from '@/mixins/forms';
import Routes from '@/mixins/routes';

import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import HelpText from '@/components/HelpText.vue';
import Word from '@/components/forms/Word.vue';
import WordOrImage from '@/components/forms/WordOrImage.vue';
import Select from '@/components/forms/Select.vue';
import Nice from '@/components/forms/Nice.vue';
import Date from '@/components/forms/Date.vue';
import Text from '@/components/forms/Text.vue';
import MutationKind from '@/components/forms/MutationKind.vue';
import MutationType from '@/components/forms/MutationType.vue';
import TrademarkKind from '@/components/forms/TrademarkKind.vue';
import TrademarkType from '@/components/forms/TrademarkType.vue';
import AbsoluteGrounds from '@/components/forms/AbsoluteGrounds.vue';
import { Action } from 'vuex-class';
import Refusals from '@/components/forms/Refusals.vue';

@Component({
    components: {
        HelpText,
        word: Word,
        wordOrImage: WordOrImage,
        goods: Select,
        imageMark: Text,
        nice: Nice,
        registration: Text,
        depotDate: Date,
        publicationDate: Date,
        renewalDate: Date,
        holder: Text,
        mutationNumber: Text,
        publicationDateMutation: Date,
        mutationType: MutationKind,
        applicationNumber: Text,
        kind: TrademarkKind,
        type: TrademarkType,
        filingDate: Date,
        publicationDateOfDefinitiveRefusal: Date,
        refusalReason: AbsoluteGrounds,
        definitiveRefusalResult: Refusals,
        registrationDate: Date,
        publicationDateFiling: Date,
        publicationDateRegistration: Date,
        publicationDateRenewal: Date,
    },
    inheritAttrs: false,
})
export default class Base extends mixins(Forms, Routes) {
    @Prop({ required: true }) component: string;
    @Prop() value;

    // @ts-ignore
    @Action removeAdvancedSearchField;
}
