




























import AdvancedSearchPopup from './AdvancedSearchPopup.vue';
import BaseFilter from '../forms/Base.vue';
import { mapActions } from 'vuex';
import { Component, Vue } from 'vue-property-decorator';
import { Getter, State } from 'vuex-class';

@Component({
    components: {
        BaseFilter,
        AdvancedSearchPopup,
    },
    methods: {
        ...mapActions([
            'removeAdvancedSearchField',
        ]),
    },
})
export default class AdvancedSearchForm extends Vue {
    popupVisible: boolean = false;
    modalName: string = 'lookAtMe';

    @State('imageMode')
    imageMode!: boolean;

    @Getter('queryContainsImage')
    queryContainsImage!: boolean;

    get formData() {
        return this.$store.state.advancedSearch;
    }

    updateState(field, value) {
        this.$store.commit('setAdvancedSearchFieldValue', { field, value });
    }

    search() {
        this.$store.commit('resetOffice');

        this.$store.commit('setPage', 1);
        this.$store.dispatch('advancedSearch', this.$store.state.advancedSearch);
    }

    toggleFilters() {
        this.popupVisible = !this.popupVisible;
    }

    closeModal() {
        this.$modal.hide(this.modalName);
    }

    fixFilters() {
        this.$modal.hide(this.modalName);
    }

    get disableFilterButton() {
        return this.imageMode === true || this.queryContainsImage;
    }

    get displayPopup() {
        return this.imageMode === false && this.popupVisible && !this.queryContainsImage;
    }
}
