


















import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';

@Component
export default class EnableImageModeModal extends Vue {
    @Prop({ required: true })
    name!: string;

    @Action('setAdvancedSearchFields')
    setAdvancedSearchFields!: (filters: Array<string>) => void;

    @State('fields')
    fields!: Array<string>;

    closeModal() {
        this.$modal.hide(this.name);
    }

    disableFilters() {
        this.$emit('ok');
        this.setAdvancedSearchFields([
            'wordOrImage',
            'goods',
        ]);
        this.$modal.hide(this.name);
    }

    get displayedText(): string {
        return this.$t('search.image.disclaimer').toString();
    }
}
