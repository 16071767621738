<template>
    <select :id="id" :value="value" :class="$style.input" @input="$emit('input', $event.target.value)">
        <option v-for="option in options" :key="option.value" :value="option.value">
            {{ option.label }}
        </option>
    </select>
</template>

<script>
    import Forms from '@/mixins/forms';

    export default {
        mixins: [Forms],

        props: {
            value: {
                type: String,
                default: '',
            },
        },

        computed: {
            options() {
                return [
                    { value: 'Word', label: this.$t('search.trademark_type.word') },
                    { value: 'Figurative', label: this.$t('search.trademark_type.figurative') },
                    { value: 'Figurative-word', label: this.$t('search.trademark_type.figurative-word') },
                    { value: 'Motion', label: this.$t('search.trademark_type.motion') },
                    { value: '3D', label: this.$t('search.trademark_type.3d') },
                    { value: '3D-word', label: this.$t('search.trademark_type.3d-word') },
                    { value: 'Pattern', label: this.$t('search.trademark_type.pattern') },
                    { value: 'Position', label: this.$t('search.trademark_type.position') },
                    { value: 'Sound', label: this.$t('search.trademark_type.sound') },
                    { value: 'Multimedia', label: this.$t('search.trademark_type.multimedia') },
                    { value: 'Hologram', label: this.$t('search.trademark_type.hologram') },
                    { value: 'Colour', label: this.$t('search.trademark_type.colour') },
                    { value: 'Other', label: this.$t('search.trademark_type.other') },
                ];
            },
        },
    };
</script>

<style module lang="scss">
    @include formGroup;
    @include input {
        padding: .8rem 1rem;
    };
</style>
