<template>
    <div>
        <div :class="$style.searchHeader">
            <h2>{{ $t('search.advancedSearch') }}</h2>

            <div :class="$style.buttonLink" @click="goToSimpleSearch()">
                {{ $t('search.simpleSearch') }}
            </div>
        </div>

        <AdvancedSearchTypeSwitcher />

        <MutationSearchForm />

        <SearchErrors />

        <SearchResults />
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import SearchResults from '../components/SearchResults';
    import AdvancedSearchTypeSwitcher from '../components/AdvancedSearch/AdvancedSearchTypeSwitcher';
    import MutationSearchForm from '../components/Mutations/MutationSearchForm';
    import SearchErrors from '@/components/SearchErrors';

    export default {
        name: 'AdvancedSearch',

        components: {
            SearchResults,
            SearchErrors,
            AdvancedSearchTypeSwitcher,
            MutationSearchForm,
        },

        methods: {
            ...mapActions(['resetSearchResults', 'resetAdvancedSearch']),
            goToSimpleSearch() {
                // When we switch to simple search, we want to reset the search results...
                this.resetSearchResults();
                this.resetAdvancedSearch();
                this.$router.push('/');
            },
        },
    };
</script>

<style module lang="scss">
    .buttonLink {
        @include button-link();
    }

    .searchHeader {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 6.8rem;

        h2 {
            color: $color-primary;
            margin-bottom: 0;
        }
    }
</style>
