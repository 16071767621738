<template>
    <span />
</template>

<script>
import * as Sentry from '@sentry/vue';

export default {
    name: 'RouteNotFound',

    created() {
        if (process.env.VUE_APP_SENTRY_DSN) {
            Sentry.captureMessage('Unable to find route');
        }
        this.$router.replace({ name: 'simple-search' });
    },
};
</script>
