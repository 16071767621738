








import ViewSwitcher from '@/components/ViewSwitcher.vue';
import PaginationLinks from '@/components/PaginationLinks.vue';
import ResultFilter from '@/components/ResultFilter.vue';
import { Prop, Vue, Component } from 'vue-property-decorator';

@Component({
    components: {
        ViewSwitcher,
        PaginationLinks,
        ResultFilter,
    },
})
export default class ResultHeader extends Vue {
    @Prop({ default: false })
    showOfficeFilter!: boolean;

    get page() {
        return this.$store.state.page;
    }

    set page(page) {
        this.$store.commit('setPage', page);

        switch (this.$route.name) {
            case 'mutation-search':
                this.$store.dispatch('mutationSearch');
                break;
            case 'advanced-search':
                this.$store.dispatch('advancedSearch', this.$store.state.advancedSearch);
                break;
            case 'office-decisions-search':
                this.$store.dispatch('advancedSearch', {
                    ...this.$store.state.officeDecisions,
                    exact: this.$store.state.advancedSearch.exact,
                });
                break;
            default:
                this.$store.dispatch('search');
        }
    }

    get pageCount() {
        return Math.ceil(this.$store.state.count / this.$store.state.pageSize);
    }

    get view() {
        return this.$store.state.view;
    }

    set view(view) {
        this.$store.commit('setView', view);
    }
}
