<template>
    <div style="width: 100%;">
        <input :id="'advancedSearch_' + name" :value="value" type="text" size="15" maxlength="128" :class="$style.input" @input="$emit('input', $event.target.value)">
    </div>
</template>

<script>
    import Forms from '@/mixins/forms';

    export default {
        mixins: [Forms],

        props: {
            value: {
                type: String,
                default: '',
            },
        },
    };
</script>

<style module lang="scss">
    @include formGroup;
    @include input;
</style>
