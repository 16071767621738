








import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import Forms from '@/mixins/forms';

@Component
export default class Refusals extends mixins(Forms) {
    @Prop({ default: '' }) value: string;

    get options() {
        return [
            { value: 'partialDefinitiveRefusal,totalDefinitiveRefusal', label: this.$t('officeDecisions.refusals.all') },
            { value: 'totalDefinitiveRefusal', label: this.$t('officeDecisions.refusals.whole') },
            { value: 'partialDefinitiveRefusal', label: this.$t('officeDecisions.refusals.partial') },
        ];
    }
}
