









































import { Component, Prop, Vue } from 'vue-property-decorator';
import Query from '@/models/Query';
import { QueryType } from '@/enums/QueryType';

@Component
export default class ImageInput extends Vue {
    @Prop({ required: true })
    value!: Query;

    $style!: any;
    isDragging: boolean = false;
    isUploading: boolean = false;
    isDraggingOverElement: boolean = false;
    dragPageCounter: number = 0;
    dragInsideCounter: number = 0;
    maxFileSize: number = 1468006;
    allowedFiles = ['image/gif', 'image/jpg', 'image/jpeg', 'image/png'];

    mounted() {
        window.addEventListener('dragenter', this.imageDraggedOnPage);
        window.addEventListener('dragleave', this.imageDraggedOutOfPage);
    }

    beforeDestroy() {
        window.removeEventListener('dragenter', this.imageDraggedOnPage);
        window.removeEventListener('dragleave', this.imageDraggedOutOfPage);
    }

    imageAdded(event) {
        if (event.target.files.length !== 0) {
            this.uploadFile(event.target.files[0]);
        }
    }

    imageDropped(dropEvent) {
        dropEvent.preventDefault();
        this.isDragging = false;
        this.isDraggingOverElement = false;
        this.isUploading = true;
        this.dragPageCounter = 0;
        this.dragInsideCounter = 0;

        if (dropEvent.dataTransfer.items) {
            const uploadedFiles = dropEvent.dataTransfer.items;
            for (let uploadedFile of uploadedFiles) {
                if (uploadedFile.kind === 'file') {
                    const file = uploadedFile.getAsFile();

                    this.uploadFile(file);
                }
            }
        } else {
            const uploadedFiles = dropEvent.dataTransfer.files;
            for (let uploadedFile of uploadedFiles) {
                this.uploadFile(uploadedFile);
            }
        }

        this.isUploading = false;
    }

    uploadFile(file) {
        if (this.allowedFiles.includes(file.type) && file.size < this.maxFileSize) {
            this.$emit('error', null);
            const fileReader = new FileReader();
            fileReader.onload = () => {
                this.$emit('input', {
                    label: file.name,
                    value: fileReader.result,
                    type: QueryType.IMAGE,
                });
                this.$emit('close');
            };
            fileReader.readAsDataURL(file);
        } else {
            if (file.size > this.maxFileSize) {
                this.$emit('error', this.$t('search.image.file_to_big'));

                return;
            }

            this.$emit('error', this.$t('search.image.file_not_supported'));
        }
    }

    imageDraggedOutOfPage(e: any) {
        e.preventDefault();
        this.dragPageCounter--;

        if (this.dragPageCounter <= 0) {
            this.isDragging = false;
        }
    }

    imageDraggedOnPage(e: any) {
        e.preventDefault();
        this.dragPageCounter++;

        this.isDragging = true;
    }

    draggedOverElement() {
        this.dragInsideCounter++;
        this.isDraggingOverElement = true;
    }

    draggedOutOfElement() {
        this.dragInsideCounter--;

        if (this.dragInsideCounter <= 0) {
            this.isDraggingOverElement = false;
        }
    }

    get dropAreaClass(): string {
        if (this.isDragging || this.isUploading) {
            return this.$style.dragging;
        }

        return '';
    }
}
