
















import { Component, Vue } from 'vue-property-decorator';
import ImageInput from '@/components/forms/ImageInput.vue';
import Query from '@/models/Query';
import WordOrImage from '@/components/forms/WordOrImage.vue';
import HelpText from '@/components/HelpText.vue';
import Word from '@/components/forms/Word.vue';

@Component({
    components: {
        Word,
        HelpText,
        WordOrImage,
        FileDrop: ImageInput,
    },
})
export default class SearchForm extends Vue {
    search() {
        this.$store.commit('resetOffice');
        this.$store.commit('setPage', 1);
        this.$store.dispatch('search');
    }

    get query(): Query|null {
        return this.$store.state.query;
    }

    set query(query: Query|null) {
        this.$store.commit('setQuery', query);
    }
}
