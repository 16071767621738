import moment from 'moment';
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class Dates extends Vue {
    formatDate(date, format) {
        if (!date) return '';
        if (!format) format = 'DD-MM-YYYY';

        return moment.parseZone(date).format(format);
    }

    formatDateTime(dateTime, format) {
        if (!format) format = 'DD-MM-YYYY , HH.mm';

        return moment.parseZone(dateTime).format(format);
    }
}
