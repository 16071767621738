



















import GridIcon from '@/assets/icons/grid.svg';
import RowIcon from '@/assets/icons/list.svg';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    components: {
        GridIcon,
        RowIcon,
    },
    model: {
        prop: 'view',
        event: 'change',
    },
})
export default class ViewSwitcher extends Vue {
    @Prop({
        required: true,
        validator(value) {
            return ['grid', 'row'].indexOf(value) !== -1;
        },
    })
    view!: string;

    setView(value) {
        this.$emit('change', value);
    }
}
